import { createApp } from 'vue'
import App from './App.vue'
import { Amplify } from 'aws-amplify';
import AmplifyVue from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
import awsconfig from './aws-exports';
import router from './router'
import { createPinia } from 'pinia'

import { createVuestic } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import "./assets/overrides.css"

// import { registerSW } from 'virtual:pwa-register'
// registerSW({ immediate: true })

const pinia = createPinia();




import './registerServiceWorker'


Amplify.configure(awsconfig);

const app = createApp(App);


app.use(router)
app.use(pinia)
app.use(AmplifyVue)
app.use(createVuestic( 
  {
    config: {
      colors: {
        primary: '#FFF',
        secondary: '#000',
        success: '#079d8d',
        button:  '#000',
        background: '#FFF',
        danger: '#d73529',
      },

    }
    
}
))

app.mount('#app')
