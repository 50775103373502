
import { createWord as mutation_createWord } from "../../graphql/mutations";
import { deleteWord as mutation_deleteWord } from "../../graphql/mutations";
import { updateWord as mutation_updateWord } from "../../graphql/mutations";
import { listWords as query_listWords } from "../../graphql/queries";
import { getWord as query_getWord } from "../../graphql/myQueries";


import { createMorpheme as mutation_createMorpheme } from "../../graphql/mutations";
import { deleteMorpheme as mutation_deleteMorpheme } from "../../graphql/mutations";
import { updateMorpheme as mutation_updateMorpheme } from "../../graphql/mutations";
import { listMorphemes as query_listMorphemes } from "../../graphql/myQueries";

import { listWordCounts as query_listWordCounts } from "../../graphql/queries";

import { createSyllable as mutation_createSyllable } from "../../graphql/mutations";
import { listSyllables as query_listSyllables } from "../../graphql/queries";

import { onUpdateWord as subscription_onUpdateWord } from "../../graphql/mySubscriptions";
import { onCreateWord as subscription_onCreateWord } from "../../graphql/mySubscriptions";
import { onDeleteWord as subscription_onDeleteWord } from "../../graphql/mySubscriptions";

import { listSpeakerWords as query_listSpeakerWords } from "../../graphql/myQueries";
import { getSpeakerWord as query_getSpeakerWord } from "../../graphql/myQueries";

import { listSpeakerSyllables as query_listSpeakerSyllables } from "../../graphql/myQueries";
import { getSpeakerSyllable as query_getSpeakerSyllable } from "../../graphql/myQueries";

import { onUpdateMorpheme as subscription_onUpdateMorpheme } from "../../graphql/mySubscriptions";
import { onCreateMorpheme as subscription_onCreateMorpheme } from "../../graphql/mySubscriptions";
import { onDeleteMorpheme as subscription_onDeleteMorpheme } from "../../graphql/mySubscriptions";

import { onCreateWordCount as subscription_onCreateWordCount} from "../../graphql/subscriptions";
import { onUpdateWordCount as subscription_onUpdateWordCount} from "../../graphql/subscriptions";

import { defineStore } from 'pinia'
import { API, graphqlOperation } from 'aws-amplify';



export const useWords = defineStore('words', {
  
  state: () => {
    return {
        words: {}, 
        morphemes: {},
        syllables: {},
        subscriptions: {},
        wordCounts: {},
    }
  },

  getters () {

    return {
        words: (state)=>state.words,
        wordCounts: (state)=>state.wordCounts,
        morphemes: (state)=>state.morphemes,
        syllables: (state)=>state.morphemes,
    }        
  },

  actions: {

    
        async listAllMorphemes()
        {
            try{

                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_listMorphemes, queryParams)
                    )
            
                    let records = page.data.listMorphemes.items;
                    nextToken = page.data.listMorphemes.nextToken;
                    
                    for(let record of records)
                    {
                        allRecords[record.sro] = record;
                    }
                    
                    pages++;
                }
                
                this.morphemes = allRecords;

                
            } catch(error){
                console.error(error);
            }
        },

        async shiftWords(level)
        {
            try{

                console.log('Shift words at level ' + level)

                let pages = 0;
                let nextToken = null;

                let queryParams = {
                    filter: {
                        level: { eq: level }
                    }
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_listWords, queryParams)
                    )
                
                    let words = page.data.listWords.items;
                    nextToken = page.data.listWords.nextToken;
                    
                    for(let record of words)
                    {
                        await API.graphql(
                            // graphqlOperation(mutation_updateWord, { input: { sro: record.sro, level: +record.level + 20 }})
                            // graphqlOperation(mutation_updateWord, { input: { sro: record.sro, level: 56 }})
                        )
                        console.log(record);

                    }
                    
                    pages++;
                }


                
            } catch(error){
                console.error(error);
            }
        },

        async addNotesToWords(level)
        {
            try{

                console.log('Add notes to words at level ' + level)

                let pages = 0;
                let nextToken = null;

                let queryParams = {
                    filter: {
                        level: { eq: level }
                    }
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_listWords, queryParams)
                    )
                
                    let words = page.data.listWords.items;
                    nextToken = page.data.listWords.nextToken;
                    
                    for(let record of words)
                    {
                        if(record.notes == null)
                        {
                            await API.graphql(
                                graphqlOperation(mutation_updateWord, { input: { sro: record.sro, notes: "" }})
                            )
                            console.log(record);
                        }
                        

                    }
                    
                    pages++;
                }


                
            } catch(error){
                console.error(error);
            }
        },


        
        async listWordCounts()
        {
            try{
                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {};

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_listWordCounts, queryParams)
                    )
            
                    let records = page.data.listWordCounts.items;
                    nextToken = page.data.listWordCounts.nextToken;
                    for(let record of records)
                    {
                        allRecords[record.level] = record.count;
                    }

                    
                    pages++;
                }
                
                this.wordCounts = allRecords;

                
            } catch(error){
                console.error(error);
            }
        },


        
        async listAllSyllables()
        {
            try{

                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_listSyllables, queryParams)
                    )
            
                    let records = page.data.listSyllables.items;
                    nextToken = page.data.listSyllables.nextToken;
                    
                    for(let record of records)
                    {
                        allRecords[record.sro] = record;
                    }
                    
                    pages++;
                }
                
                this.syllables = allRecords;

                
            } catch(error){
                console.error(error);
            }
        },


            async listAllWords()
        {
            try{

                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_listWords, queryParams)
                    )
                
                    let words = page.data.listWords.items;
                    nextToken = page.data.listWords.nextToken;
                    
                    for(let record of words)
                    {
                        if(record.morphemeWordsId == null) record.morphemeWordsId = "none";
                        allRecords[record.sro] = record;

                    }
                    
                    pages++;
                }

                this.words = allRecords;
                

                
            } catch(error){
                console.error(error);
            }
        },

        async getWord(sro)
        {
            try{
                let query = await API.graphql(
                    graphqlOperation(query_getWord, {  sro: sro })
                )

                
                if(query.data.getWord) this.words[query.data.getWord.sro] = query.data.getWord;
                else{
                    console.log("Word does not exist: " + sro);
                }

                return

            }catch(err)
            {
                console.log(sro);
                console.error(err);

            }
        },


        async createWord(createObject)
        {
            try{

                createObject.num_syllables = createObject.syllabics.length;

                let newWord = await API.graphql(
                    graphqlOperation(mutation_createWord, { input: createObject })
                )

                this.words[newWord.data.createWord.sro] = newWord.data.createWord;

                return

            }catch(err)
            {
                console.error(err);

            }
        },

        async deleteWord(sro)
        {
            try{

                console.log(sro);

                await API.graphql(
                    graphqlOperation(mutation_deleteWord, { input: { sro: sro } })
                )

                return

            }catch(err)
            {
                console.error(err);

            }
        },  

        async updateWord(updates)
        {
            if(Object.prototype.hasOwnProperty.call(updates, 'syllabics')) updates.num_syllables = updates.syllabics.length;

            try{
                await API.graphql(
                    graphqlOperation(mutation_updateWord, { input: updates })
                )

                return

            }catch(err)
            {
                console.error(err);

            }
        },  

        async createMorpheme(createObject)
        {
            try{

                let newWord = await API.graphql(
                    graphqlOperation(mutation_createMorpheme, { input: createObject })
                )

                this.morphemes[newWord.data.createMorpheme.sro] = newWord.data.createMorpheme;

                return

            }catch(err)
            {
                console.error(err);

            }
        },

        async deleteMorpheme(sro)
        {
            try{

                await API.graphql(
                    graphqlOperation(mutation_deleteMorpheme, { input: { sro: sro } })
                )

                return

            }catch(err)
            {
                console.error(err);

            }
        },  

        async updateMorpheme(updates)
        {
            try{

                await API.graphql(
                    graphqlOperation(mutation_updateMorpheme, { input: updates })
                )

                return

            }catch(err)
            {
                console.error(err);

            }
        },  

        async createSyllable(createObject)
        {
            try{

                let newSyllable = await API.graphql(
                    graphqlOperation(mutation_createSyllable, { input: createObject })
                )

                this.syllables[newSyllable.data.createWord.sro] = newSyllable.data.createSyllable;

                return

            }catch(err)
            {
                console.error(err);
            }
        },

        async getSpeakerAudioForWord(sro, speakerID)
        {
            
            if(speakerID)
            {

                try{
                    let query = await API.graphql(
                        graphqlOperation(query_getSpeakerWord, {  sro: sro, userID: speakerID})
                    )

                    
                    return query.data.getSpeakerWord
                }catch(err)
                {
                    console.error(err);
                }
            } else{

                try{


                    let allRecords = [];
                    let pages = 0;
                    let nextToken = null;
    
                    let queryParams = {
                        sro: sro
                    }
    
                    while(nextToken != null || pages == 0)
                    {
                        
                        if(nextToken != null) queryParams.nextToken = nextToken;
    
                        let page = await API.graphql(
                            graphqlOperation(query_listSpeakerWords, queryParams)
                        )

                    
                        let words = page.data.listSpeakerWords.items;
                        nextToken = page.data.listSpeakerWords.nextToken;
                        
                        for(let record of words)
                        {
                            allRecords.push(record);
                        }
                        
                        pages++;
                    }

                    if(allRecords.length == 0)
                    {
                        return {
                            bucketID: "ca-central-1:3caa724d-ef46-437c-b148-4bf09568e44f",
                            audio: {
                                key: "āha.mp3"
                            }
                        }
                    }
                    let index = Math.floor(Math.random() * (allRecords.length));
                    return allRecords[index];
                    
    
                    
                } catch(error){
                    console.error(error);
                }

            }
        
        },

        async getSpeakerAudioForSyllable(syllable, speakerID)
        {
            let sro = null;
            for(let s in this.syllables)
            {
                if(this.syllables[s].syllable == syllable) { sro = s; break }
            }

            if(speakerID)
            {

                try{
                    let query = await API.graphql(
                        graphqlOperation(query_getSpeakerSyllable, {  sro: sro, userID: speakerID})
                    )
                    return query.data.getSpeakerSyllable
                }catch(err)
                {
                    console.error(err);
                }
            } else{

                try{

                    let allRecords = [];
                    let pages = 0;
                    let nextToken = null;
    
                    let queryParams = {
                        sro: sro
                    }
    
                    while(nextToken != null || pages == 0)
                    {
                        
                        if(nextToken != null) queryParams.nextToken = nextToken;
    
                        let page = await API.graphql(
                            graphqlOperation(query_listSpeakerSyllables, queryParams)
                        )

                    
                        let words = page.data.listSpeakerSyllables.items;
                        nextToken = page.data.listSpeakerSyllables.nextToken;
                        
                        for(let record of words)
                        {
                            allRecords.push(record);
                        }
                        
                        pages++;
                    }

                    let index = Math.floor(Math.random() * (allRecords.length));
                    return allRecords[index];
                    
    
                    
                } catch(error){
                    console.error(error);
                }

            }
        
        },

        

        async setupSubscriptions(explicit)
        {

            if(explicit == null || explicit == 'onCreateWord')
            {
                if(this.subscriptions['onCreateWord']) 
                {
                    await this.subscriptions['onCreateWord'].unsubscribe();
                    this.subscriptions['onCreateWord'] = null
                }

                this.subscriptions['onCreateWord'] = API.graphql(
                    graphqlOperation(subscription_onCreateWord)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onCreateWord;        
                        this.words[data.sro] = data;

                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onCreateWord');
    
                        },1000)
                    }
                });
            }

            
            if(explicit == null || explicit == 'onUpdateWord')
            {
                if(this.subscriptions['onUpdateWord']) 
                {
                    await this.subscriptions['onUpdateWord'].unsubscribe();
                    this.subscriptions['onUpdateWord'] = null
                }

                this.subscriptions['onUpdateWord'] = API.graphql(
                    graphqlOperation(subscription_onUpdateWord)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateWord;
                        if(this.words[data.sro])
                        {
                            for(let key in data)
                            {
                                if(this.words[data.sro][key] != data[key]) this.words[data.sro][key] = data[key];
                            }    
                        }

                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateWord');
    
                        },1000)
                    }
                });
            }

                        
            if(explicit == null || explicit == 'onDeleteWord')
            {
                if(this.subscriptions['onDeleteWord']) 
                {
                    await this.subscriptions['onDeleteWord'].unsubscribe();
                    this.subscriptions['onDeleteWord'] = null
                }

                this.subscriptions['onDeleteWord'] = API.graphql(
                    graphqlOperation(subscription_onDeleteWord)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onDeleteWord;        
                        if(this.words[data.sro])
                        {
                            delete this.words[data.sro]
                        }
                        
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onDeleteWord');
    
                        },1000)
                    }
                });
            }

                                
            if(explicit == null || explicit == 'onCreateMorpheme')
            {
                if(this.subscriptions['onCreateMorpheme']) 
                {
                    await this.subscriptions['onCreateMorpheme'].unsubscribe();
                    this.subscriptions['onCreateMorpheme'] = null
                }
                this.subscriptions['onCreateMorpheme'] = API.graphql(
                    graphqlOperation(subscription_onCreateMorpheme)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onCreateMorpheme;        
                        this.morphemes[data.sro] = data;
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onCreateMorpheme');
    
                        },1000)
                    }
                });
            }

                                            
            if(explicit == null || explicit == 'onUpdateMorpheme')
            {
                if(this.subscriptions['onUpdateMorpheme']) 
                {
                    await this.subscriptions['onUpdateMorpheme'].unsubscribe();
                    this.subscriptions['onUpdateMorpheme'] = null
                }
                this.subscriptions['onUpdateMorpheme'] = API.graphql(
                    graphqlOperation(subscription_onUpdateMorpheme)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateMorpheme;
                        if(this.morphemes[data.sro])
                        {
                            for(let key in data)
                            {
                                if(this.morphemes[data.sro][key] != data[key]) this.morphemes[data.sro][key] = data[key];
                            }    
                        }

                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateMorpheme');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onDeleteMorpheme')
            {
                if(this.subscriptions['onDeleteMorpheme']) 
                {
                    await this.subscriptions['onDeleteMorpheme'].unsubscribe();
                    this.subscriptions['onDeleteMorpheme'] = null
                }
                this.subscriptions['onDeleteMorpheme'] = API.graphql(
                    graphqlOperation(subscription_onDeleteMorpheme)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onDeleteMorpheme;        
                        if(this.morphemes[data.sro])
                        {
                            delete this.morphemes[data.sro]
                        }
                        
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onDeleteMorpheme');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onCreateWordCount')
            {
                if(this.subscriptions['onCreateWordCount']) 
                {
                    await this.subscriptions['onCreateWordCount'].unsubscribe();
                    this.subscriptions['onCreateWordCount'] = null
                }
                this.subscriptions['onCreateWordCount'] = API.graphql(
                    graphqlOperation(subscription_onCreateWordCount)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onCreateWordCount;        
                        this.wordCounts[data.level] = data.count;
                        console.log(this.wordCounts);
                     
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onDeleteMorpheme');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onUpdateWordCount')
            {
                if(this.subscriptions['onUpdateWordCount']) 
                {
                    await this.subscriptions['onUpdateWordCount'].unsubscribe();
                    this.subscriptions['onUpdateWordCount'] = null
                }
                this.subscriptions['onUpdateWordCount'] = API.graphql(
                    graphqlOperation(subscription_onUpdateWordCount)
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateWordCount;        
                        this.wordCounts[data.level] = data.count;
                        console.log(this.wordCounts);
                     
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onDeleteMorpheme');
    
                        },1000)
                    }
                });
            }
        }

    }

})