export default{
    syllabics: [
        
            {//VOWEL
                "ᐁ": {
                    "sro": "ē",
                    "CwV": true
                },
                "ᐃ": {
                    "sro": "i",
                    "CwV": true
                },
                "ᐅ": {
                    "sro": "o",
                    "CwV": true
                },
                "ᐊ": {
                    "sro": "a",
                    "CwV": true
                },
                "ᐄ": {
                    "sro": "ī",
                    "CwV": true
                },
                "ᐆ": {
                    "sro": "ō",
                    "CwV": true
                },
                "ᐋ": {
                    "sro": "ā",
                    "CwV": false
                }
            },
            {//w
                "ᐍ": {
                    "sro": "wē",
                    "CwV": true
                },
                "ᐏ": {
                    "sro": "wi",
                    "CwV": true
                },
                "ᐓ": {
                    "sro": "wo",
                    "CwV": true
                },
                "ᐘ": {
                    "sro": "wa",
                    "CwV": true
                },
                "ᐑ": {
                    "sro": "wī",
                    "CwV": true
                },
                "ᐕ": {
                    "sro": "wō",
                    "CwV": true
                },
                "ᐚ": {
                    "sro": "wā",
                    "CwV": true
                },
                "ᐤ": {
                    "sro": "w",
                    "CwV": false
                }
            },
            {//p
                "ᐯ": {
                    "sro": "pē",
                    "CwV": true
                },
                "ᐱ": {
                    "sro": "pi",
                    "CwV": true
                },
                "ᐳ": {
                    "sro": "po",
                    "CwV": true
                },
                "ᐸ": {
                    "sro": "pa",
                    "CwV": true
                },
                "ᐲ": {
                    "sro": "pī",
                    "CwV": true
                },
                "ᐴ": {
                    "sro": "pō",
                    "CwV": true
                },
                "ᐹ": {
                    "sro": "pā",
                    "CwV": true
                },
                "ᑊ": {
                    "sro": "p",
                    "CwV": false
                }       
            },
            {//t
                "ᑌ": {
                    "sro": "tē",
                    "CwV": true
                },
                "ᑎ": {
                    "sro": "ti",
                    "CwV": true
                },
                "ᑐ": {
                    "sro": "to",
                    "CwV": true
                },
                "ᑕ": {
                    "sro": "ta",
                    "CwV": true
                },
                "ᑏ": {
                    "sro": "tī",
                    "CwV": true
                },
                "ᑑ": {
                    "sro": "tō",
                    "CwV": true
                },
                "ᑖ": {
                    "sro": "tā",
                    "CwV": true
                },
                "ᐟ": {
                    "sro": "t",
                    "CwV": false
                },   
            },
            {//k
                "ᑫ": {
                    "sro": "kē",
                    "CwV": true
                },
                "ᑭ": {
                    "sro": "ki",
                    "CwV": true
                },
                "ᑯ": {
                    "sro": "ko",
                    "CwV": true
                },
                "ᑲ": {
                    "sro": "ka",
                    "CwV": true
                },
                "ᑮ": {
                    "sro": "kī",
                    "CwV": true
                },
                "ᑰ": {
                    "sro": "kō",
                    "CwV": true
                },
                "ᑳ": {
                    "sro": "kā",
                    "CwV": true
                },
                "ᐠ": {
                    "sro": "k",
                    "CwV": false
                },
            },
            {//c
                "ᒉ": {
                    "sro": "cē",
                    "CwV": true
                },
                "ᒋ": {
                    "sro": "ci",
                    "CwV": true
                },
                "ᒍ": {
                    "sro": "co",
                    "CwV": true
                },
                "ᒐ": {
                    "sro": "ca",
                    "CwV": true
                },
                "ᒌ": {
                    "sro": "cī",
                    "CwV": true
                },
                "ᒎ": {
                    "sro": "cō",
                    "CwV": true
                },
                "ᒑ": {
                    "sro": "cā",
                    "CwV": true
                },
                "ᐨ": {
                    "sro": "c",
                    "CwV": false
                },
                
            },
            {//m
                "ᒣ": {
                    "sro": "mē",
                    "CwV": true
                },
                "ᒥ": {
                    "sro": "mi",
                    "CwV": true
                },
                "ᒧ": {
                    "sro": "mo",
                    "CwV": true
                },
                "ᒪ": {
                    "sro": "ma",
                    "CwV": true
                },
                "ᒦ": {
                    "sro": "mī",
                    "CwV": true
                },
                "ᒨ": {
                    "sro": "mō",
                    "CwV": true
                },
                "ᒫ": {
                    "sro": "mā",
                    "CwV": true
                },
                "ᒼ": {
                    "sro": "m",
                    "CwV": false
                },   
                
            },
            {//n
                "ᓀ": {
                    "sro": "nē",
                    "CwV": true
                },
                "ᓂ": {
                    "sro": "ni",
                    "CwV": true
                },
                "ᓄ": {
                    "sro": "no",
                    "CwV": true
                },
                "ᓇ": {
                    "sro": "na",
                    "CwV": true
                },
                "ᓃ": {
                    "sro": "nī",
                    "CwV": true
                },
                "ᓅ": {
                    "sro": "nō",
                    "CwV": true
                },
                "ᓈ": {
                    "sro": "nā",
                    "CwV": true
                },
                "ᐣ": {
                    "sro": "n",
                    "CwV": false
                },   
            },
            {//s
                "ᓭ": {
                    "sro": "sē",
                    "CwV": true
                },
                "ᓯ": {
                    "sro": "si",
                    "CwV": true
                },
                "ᓱ": {
                    "sro": "so",
                    "CwV": true
                },
                "ᓴ": {
                    "sro": "sa",
                    "CwV": true
                },
                "ᓰ": {
                    "sro": "sī",
                    "CwV": true
                },
                "ᓲ": {
                    "sro": "sō",
                    "CwV": true
                },
                "ᓵ": {
                    "sro": "sā",
                    "CwV": true
                },
                "ᐢ": {
                    "sro": "s",
                    "CwV": false
                }
            },
            {//y
                "ᔦ": {
                    "sro": "yē",
                    "CwV": true
                },
                "ᔨ": {
                    "sro": "yi",
                    "CwV": true
                },
                "ᔪ": {
                    "sro": "yo",
                    "CwV": true
                },
                "ᔭ": {
                    "sro": "ya",
                    "CwV": true
                },
                "ᔩ": {
                    "sro": "yī",
                    "CwV": true
                },
                "ᔫ": {
                    "sro": "yō",
                    "CwV": true
                },
                "ᔮ": {
                    "sro": "yā",
                    "CwV": true
                },
                "ᐩ": {
                    "sro": "y",
                    "CwV": false
                }
            },
            {//other
                "ᐦ": {
                    "sro": "h",
                    "CwV": false
                },
                "ᕽ": {
                    "sro": "hk",
                    "CwV": false
                }
            }

        ],

    }