<template>

  <div v-if="loading == false" style="margin-bottom: 75px; padding-bottom: 75px">

    <va-modal v-model="showModal" :message="modalMessage"  />  
  


      <div style="position: fixed; bottom: -100px; left: 50%; z-index:999999; transform: translateX(-50%)">

       
          <div v-if="speakerDetails.id" key="speakerDiv">
            <div class="slideup" id='speakerBox' style="border: 2px solid white; color: #FFF; border-radius: 1500px; z-index:10000; background-color: rgb(49,20,50); width: 330px; padding: 7px 0px 7px 0px; line-height: 20px; text-align: center; " >
              <div v-if="isSafari == true" id="play-button" style="cursor: pointer; font-size: 16px; margin: 0 auto">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="25px" width="25px" fill="white" stroke="white" x="0px" y="0px" viewBox="0 0 122.88 94.98" style="enable-background:new 0 0 122.88 94.98" xml:space="preserve"><g><path class="st0" d="M5.06,20.78h20.67c0.43,0,0.79,0.35,0.79,0.79v44.72c0,0.43-0.35,0.79-0.79,0.79H5.05 C2.27,67.07,0,64.8,0,62.02V25.85C0,23.06,2.28,20.78,5.06,20.78L5.06,20.78z M109.14,1.06c5.01,7.08,8.82,15.04,11.14,23.57 c2.24,8.22,3.1,16.97,2.33,25.94c-0.7,8.2-2.7,15.99-5.79,23.18c-2.59,6.03-5.95,11.64-9.93,16.7c-0.62,0.82-1.25,1.63-1.9,2.43 l-0.05-0.05c-0.31,0.36-0.62,0.72-0.94,1.07l-0.94,1.06l-0.92-1.08l-3.73-4.38l-0.69-0.81l0.7-0.8c4.54-5.23,8.3-11.2,11.06-17.73 c2.66-6.29,4.39-13.11,4.99-20.29c0.68-7.98-0.08-15.75-2.06-23.05c-1.83-6.75-4.7-13.11-8.44-18.86l-0.02-0.02 c-0.68-0.99-1.39-1.97-2.13-2.92l0.08-0.07l-0.05-0.07l0.97-0.72l0.71-0.53l2.71-2.19l0.09,0.12l1.05-0.78l1.02-0.76L109.14,1.06 L109.14,1.06z M108.58,4.66c-0.25-0.38-0.49-0.75-0.75-1.12l-0.03,0.02C108.07,3.92,108.33,4.29,108.58,4.66L108.58,4.66z M92.75,12.19c3.68,5.28,6.47,11.21,8.17,17.55c1.64,6.12,2.26,12.61,1.7,19.27c-0.48,5.68-1.8,11.11-3.83,16.17 c-2.1,5.23-4.95,10.05-8.4,14.32l-0.94,1.16l-0.97-1.14l-4.72-3.29l-0.66-0.77l0.62-0.8c2.91-3.72,6.01-7.87,7.77-12.35 c1.7-4.33,2.1-8.99,2.51-13.88c0.5-5.86-0.04-11.55-1.47-16.9c-1.34-5.04-3.48-9.8-6.27-14.11c-0.37-0.53-0.75-1.06-1.15-1.58 l0.05-0.04l-0.46-0.66l0.96-0.71L91,11.9l1.03-0.76L92.75,12.19L92.75,12.19z M89.84,15.13l-0.31-0.47l-0.02,0.01L89.84,15.13 L89.84,15.13z M31,19.96C43.04,13.35,55.09,6.74,67.13,0.14c1.16-0.63,2.4,1.08,2.4,2.4v82.04c0,1.39-1.32,3.21-2.53,2.53L31,66.8 c-0.23-0.13-0.48-0.21-0.48-0.48V20.44C30.52,20.17,30.77,20.09,31,19.96L31,19.96z"/></g></svg>
              </div>
              
              <div style="font-size: 16px; margin: 0 auto">{{ speakerDetails.name }}</div>
              <div style="font-size: 12px; font-style: italic; margin: 0 auto">{{ speakerDetails.location ? speakerDetails.location : '' }}</div>
            </div>
          </div>
   
      </div>

      <Transition>
    
          <div v-if="sortedWordList.length < 3" style="width: 90%; opacity: 1; background: #FFF; position: fixed; bottom: 75px; z-index: 1; left: 50%; font-size: 32px; transform: translateX(-50%)">
    
            <div
              v-if="userStore.myRequests.unlock_words != true && sortedWordList.length < 2"
              class="inputButtonLarge"
              style="width: 100%; margin-top: 25px; margin-bottom: 10px; font-size: 20px; padding: 0px;"
              @click="unlockWordPoints()"
            >
              {{ computedClaimWordContent }} 

            </div>

            <va-progress-circle v-else-if="userStore.myRequests.unlock_words == true" size="small" style="margin: 0 auto;" indeterminate />

            <div v-else-if="sortedWordList.length == 2"
              class="inputButtonLarge"
              style="width: 100%; cursor: default; margin-top: 25px; margin-bottom: 10px; font-size: 20px; padding: 0px;"
            >
            {{ computedClaimWordContent }}

            </div>


          </div>
      
          
      </Transition>


        <table  v-if="mode == 0" class="va-table" style="width: 90%; margin-left: 5%; margin-top: 35px; margin-bottom: 135px; text-align: left;">


        <template v-for="(letter, l) in morphemeLetterOptions" :key="'letterHeader' + l">
          <tr>
            <td colspan='3' class="letterHeader" style="font-size: 48px; padding-top: 5px; padding-bottom: 15px; font-weight: bold; text-align: left" :style="{ borderTop: l > 0 ? '1px solid rgba(137,137,137,.2)' : 'none', paddingTop: l > 0 ? '0px' : '5px'}">{{ letter }}</td>
          </tr>
          <template v-for="(word, w) in sortedWordList" :key="'wordRow' + l + w" >

            <template v-if="word.sro[0] == letter">
                <tbody :id="word.sro">
                <tr>       
                  <td :class="{ disabled: playingAudio == true }" style="width: 300px; font-size: 18px; padding-bottom: 2px;"><span style="font-weight: bold">{{ word.sro }}</span> <span style="padding-left: 10px; font-size: 16px; font-weight: 300">{{ wordStore.words[word.sro].syllabics }}</span> <span style="width: 20px; margin-left: 10px; transform: translateY(-5px); height: 24px; line-height: 24px; padding-bottom: 0px" v-if="userStore.myWords[word.sro]"  @click="playSpeakerAudio(word.sro)"><va-icon style="transform: translateY(-3px);" name="volume_up" size="small"></va-icon></span></td>
                  <td rowspan='3' style="width: 40px; max-width: 40px; vertical-align: bottom; padding-bottom: 2px; padding-top: 0px; font-size: 12px">{{ userStore.myWords[word.sro].level }}</td>
                </tr>

                <tr v-if="userStore.myWords[word.sro]">            
                  <td style="width: 300px; padding-left: 25px; padding-top: 0px; font-size: 14px;">{{ wordStore.words[word.sro].definition  }}</td>
                  
                </tr>

                <tr v-if="userStore.myWords[word.sro]">
                  
                  <td style="width: 300px; padding-left: 25px; padding-bottom: 5px; padding-top: 0px; font-size: 14px;" >             

                    
                      <va-progress-bar
                        size="4px"
                        
                        :content-inside="true"
                        :model-value="((userStore.myWords[word.sro].xp - wordLevelRequirements[userStore.myWords[word.sro].level-1]) / (wordLevelRequirements[userStore.myWords[word.sro].level] - wordLevelRequirements[userStore.myWords[word.sro].level-1]))*100"
                        :color="wordLevelColours[userStore.myWords[word.sro].level]"
                      >
                      </va-progress-bar>
                      
                  </td>
                
                </tr>

                <tr>
                  <td colspan='3' style="height: .15px; padding: 0px; background-color: rgba(137,137,137,0)">&nbsp;</td>
                </tr>
              </tbody>
            </template>
          </template>
              <tr>
                <td colspan='3' style="height: 35px; padding: 0px; background-color: rgba(137,137,137,0)">&nbsp;</td>
              </tr>
      </template>

        
        
 

        </table>

        <table v-if="mode == 1" class="va-table" style="width: 90%; margin-left: 5%;">

        <tr>
          <th style="text-align: center">Syllable ({{ sortedSyllableList.length }})</th>
          <th style="text-align: center">SRO</th>
        </tr>
        <tr v-for="(syl, s) in sortedSyllableList" :key="'syllableTableRow' + s">
        <td style="text-align: center">{{ wordStore.syllables[syl.sro].syllable  }}</td>
        <td style="text-align: center">{{syl.sro }}</td>
        </tr>

        </table>



    </div>
    

</template>

<script>


import { Storage } from 'aws-amplify';
import { ref, computed, reactive, watch } from 'vue';
import { useColors } from 'vuestic-ui';
import { useAuth } from '@/stores/Auth/auth'
import { useWords } from '@/stores/Words/words'
import { useUsers } from '@/stores/Users/users'
import LEVELS from "@/assets/levels.js";


export default {
  name: 'WordPage',
  components: {
  },
  props: {
  },

  setup(){

    const authStore = useAuth();
    const userStore = useUsers();
    const wordStore = useWords();

    const playingAudio = ref(false);
    const isSafari = ref(null);

    const showAppBar = ref(true);
    const scrollTimer = ref(null);

    const mode = ref(0);

    const { getColors } = useColors()
    const colors = computed(getColors)

    const showModal = ref(false);
    const modalMessage = ref("");
    const highlightedSyllable = ref(null);

    const wordLevelRequirements = reactive(LEVELS.wordXP);

    const soundEffectsActivated = ref(false);

    // const { init } = useToast()

    const soundEffects = reactive({
      'activeWord': { path: null, src: null, audio: null},
      'activeSyllable': { path: null, src: null, audio: null},
      'discover1': { path: 'Game Location Discover 1.mp3', src: null, audio: null},
      'discover2': { path: 'Game Location Discover 2.mp3', src: null, audio: null},
     

    })

    const morphemeLetterOptions = computed ( () => {
      // let opts = [
      // 'a','ā','c','ē','i','ī','k','m','n','o','ō','p','s','t','w','y'
      // ]


      let opts = [];

      for(let word of sortedWordList.value)
      {
        if(!opts.includes(word.sro[0])) opts.push(word.sro[0]);
      }
      return opts 
    })   
    
    


     const wordLevelColours = reactive({
      1: '#82BA6F',
      2: '#54A1B9',
      3: '#7D409A',
      4: '#F7954A',
      5: '#F33645',
      6: '#000',
    });
        
    let speakerDetails = reactive({
      id: null,
      user_name: null,
      name: null,
      location: null
    });


    const unlockWordPoints = async () => {

      userStore.myRequests.unlock_words = true;

      let requestParams = {
          userID: authStore.userAuthDetails.attributes.sub,
          unlock_words: true
      }

        await userStore.updateUserRequest(requestParams);
    }


    const confirmWord = async (sro) => {

         let requestParams = {
          userID: authStore.userAuthDetails.attributes.sub,
          confirm_word: sro
        }

        userStore.myWords[sro].user_confirmed = true;

        await userStore.updateUserRequest(requestParams);

    }

    
    const playSpeakerAudio = async (sro) => {

      if(playingAudio.value == true) return
      playingAudio.value = true;
      let audioDetails = await wordStore.getSpeakerAudioForWord(sro, null);

      let signedURL = await Storage.get(audioDetails.audio.key, {
          level: 'protected',
          identityId: audioDetails.bucketID,
          expires: 100,
          contentType: "audio/mp3"
        });

  

      try{

        soundEffects['activeWord'].src = new Audio(signedURL);
        soundEffects['activeWord'].audio.src = soundEffects['activeWord'].src.src;
        soundEffects['activeWord'].audio.load();

     
        speakerDetails.id = audioDetails.user.id;
        speakerDetails.user_name = audioDetails.user.user_name
        speakerDetails.name = audioDetails.user.name
        speakerDetails.location = audioDetails.user.location

        isSafari.value = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1;
      
        if(isSafari.value == true)
        {
          await new Promise(resolve => setTimeout(resolve, 100));
          let safari_audio = new Audio();
          safari_audio.src = signedURL;
          var playButton = document.getElementById('play-button');


          let speakerBox = document.getElementById('speakerBox');
          speakerBox.style.transform = "translateY(-110px)";

          // Add an event listener to the play button
          playButton.addEventListener('click', async () => {
            safari_audio.play();
           
            await new Promise(resolve => setTimeout(resolve, 1000));
            speakerBox.style.transform = "translateY(0px)";
            setTimeout(()=>{
              speakerDetails.id = null;
              speakerDetails.user_name = null;
              speakerDetails.name = null;
              speakerDetails.location = null;
              playingAudio.value = false;
            },1000)
          });

        } else{

          await new Promise(resolve => setTimeout(resolve, 100));
          
          let speakerBox = document.getElementById('speakerBox');
          speakerBox.style.transform = "translateY(-110px)";

          await playSound(soundEffects['activeWord']);
          setTimeout(()=>{
            speakerBox.style.transform = "translateY(0px)";
            setTimeout(()=>{
              speakerDetails.id = null;
              speakerDetails.user_name = null;
              speakerDetails.name = null;
              speakerDetails.location = null;
              playingAudio.value = false;
            },1000)
          },2000)
        }

      } catch(err)
      {
        playingAudio.value = false;
        console.log(err);
      }
    }


    const playSpeakerSyllableAudio = async (syllable, s) => {

      if(playingAudio.value == true) return
      playingAudio.value = true;
      let audioDetails = await wordStore.getSpeakerAudioForSyllable(syllable, null);

      let signedURL = await Storage.get(audioDetails.audio.key, {
          level: 'protected',
          identityId: audioDetails.bucketID,
          expires: 100,
          contentType: "audio/mp3"
        });

      try{
        soundEffects['activeSyllable'].src = new Audio(signedURL);
        soundEffects['activeSyllable'].audio.src = soundEffects['activeSyllable'].src.src;
        soundEffects['activeSyllable'].audio.load();

        speakerDetails.id = audioDetails.user.id;
        speakerDetails.user_name = audioDetails.user.user_name
        speakerDetails.name = audioDetails.user.name
        speakerDetails.location = audioDetails.user.location

        isSafari.value = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          navigator.userAgent &&
          navigator.userAgent.indexOf('CriOS') == -1 &&
          navigator.userAgent.indexOf('FxiOS') == -1;
      
       if(isSafari.value == true)
        {
          await new Promise(resolve => setTimeout(resolve, 100));
          let safari_audio = new Audio();
          safari_audio.src = signedURL;
          var playButton = document.getElementById('play-button');


          let speakerBox = document.getElementById('speakerBox');
          speakerBox.style.transform = "translateY(-110px)";

          // Add an event listener to the play button
          playButton.addEventListener('click', async () => {
            safari_audio.play();
            
            await new Promise(resolve => setTimeout(resolve, 1000));
            speakerBox.style.transform = "translateY(0px)";
            setTimeout(()=>{
              speakerDetails.id = null;
              speakerDetails.user_name = null;
              speakerDetails.name = null;
              speakerDetails.location = null;
              playingAudio.value = false;
            },1000)
          });

        } else{

          await new Promise(resolve => setTimeout(resolve, 100));
          
          let speakerBox = document.getElementById('speakerBox');
          speakerBox.style.transform = "translateY(-110px)";

          await playSound(soundEffects['activeWord']);
          setTimeout(()=>{
            speakerBox.style.transform = "translateY(0px)";
            setTimeout(()=>{
              speakerDetails.id = null;
              speakerDetails.user_name = null;
              speakerDetails.name = null;
              speakerDetails.location = null;
              playingAudio.value = false;
            },1000)
          },2000)
        }

      } catch(err)
      {
        playingAudio.value = false;
        console.log(err);
      }

      highlightedSyllable.value = s;

      setTimeout(()=>{
        highlightedSyllable.value = null;
      },1000)
    }

    
    const preloadSoundEffects = async () => {
     for(let effect in soundEffects)
      {
        if(soundEffects[effect].path == null) continue
        let signedURL = await Storage.get(soundEffects[effect].path, {
          level: 'protected',
          identityId: "ca-central-1:3caa724d-ef46-437c-b148-4bf09568e44f",
          expires: 100,
          contentType: "audio/mp3"
        });

        soundEffects[effect].src = new Audio(signedURL);
        
      }
      return
    }

    const playSoundEffect = async (effect) => {
      
      if(effect.src != null) await playSound(effect)
      else{
        let signedURL = await Storage.get(effect.path, {
          level: 'protected',
          identityId: "ca-central-1:3caa724d-ef46-437c-b148-4bf09568e44f",
          expires: 100,
          contentType: "audio/mp3"
        });
        
        effect.src = new Audio(signedURL);
        await playSound(effect);
        
      }

      return
    }


    const playAudioFile = async (word) => {
    
      let signedURL = await Storage.get(word.audio.key, {
          level: 'protected',
          identityId: word.bucketID,
          expires: 100,
          contentType: "audio/mp3"
        });

      try{
        soundEffects['activeWord'].src = new Audio(signedURL);
        await playSound(soundEffects['activeWord']);
      } catch(err)
      {
        console.log(err);
        showModal.value = true;
        modalMessage.value = "File not found - try waiting a few seconds if this word was just created"
      }
    }

    
    const playSound = async (soundObject) => {

      // await playSoundBuffer(soundObject);
      // return

      if(soundObject.audio == null) return
      // if(soundObject.duration > 0 && !soundObject.paused) return
      // if(soundObject.audio.src == null) soundObject.audio.src = soundObject.src.src;
      soundObject.audio.src = soundObject.src.src;
      // soundObject.audio.pause();
      
      // soundObject.audio.currentTime = null;

     

      await new Promise((resolve) => {
        try{
          
          soundObject.audio.play();
          soundObject.audio.onended = resolve
        } catch(err)
        {
          console.log(err);
        }
        
      })
      
    }

    // const playSoundBuffer = async (soundObject) => {
    //   // Create an AudioContext
    //   var audioContext = new (window.AudioContext || window.webkitAudioContext)(); 

    //   // Load the audio file using fetch
    //   await fetch(soundObject.audio.src)
    //     .then(response => response.arrayBuffer())
    //     .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
    //     .then(audioBuffer => {
    //       // Create an audio source
    //       var source = audioContext.createBufferSource();
    //       source.buffer = audioBuffer;
    //       source.connect(audioContext.destination);
    //       source.start(0);
    //     });
    // }
      

    const scrollTo = async (sro) =>{
        await new Promise(resolve => setTimeout(resolve, 100));
        // let rand = Math.random();

        

        const yOffset = -35; 
        const element = document.getElementById(sro);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        userStore.myWords[sro].isNew = null;
        element.classList.add("pulseButton");
        window.scrollTo({top: y, behavior: 'smooth'});
        

        // rand > .5 ? await playSoundEffect(soundEffects['discover2']) : await playSoundEffect(soundEffects['discover1']);
        
        
        playSpeakerAudio(sro);
        await new Promise(resolve => setTimeout(resolve, 6000));
        element.classList.remove("pulseButton");
        return
    }

    const computedClaimWordContent = computed( () => {

      let string = "";

      switch(sortedWordList.value.length)
      {
        case 0: string = "Tap To Learn Your First Word"; break;
        case 1: string = "To To Learn Your Second Word"; break;
        case 2: string = "Tap The Home Icon To Continue"; break;
        case 3: string = "Learn Your Fourth Word"; break;
        case 4: string = "Learn Your Fifth Word"; break;
      }

      return string
    })

    
    const unconfirmedNewWords = computed( () => {
      
      let list = [];

  
      if(userStore.myWords == null) return list

      for(let word in userStore.myWords)
      {
        if(userStore.myWords[word].user_confirmed == false)
        {
          list.push(userStore.myWords[word]);
        }
          
      }
  
      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })
      return list

    })


    const sortedWordList = computed ( () => {
      
      let list = [];
      if(!userStore.myWords) return list
      for(let word in userStore.myWords)
      {
        if(word.indexOf(" ") == -1)list.push(userStore.myWords[word]);
        
      }
 

      list.sort(function(a,b){
        
          if(a.sro > b.sro) return 1
          else if(a.sro < b.sro) return -1
          else if(a.level > b.level) return 1
          else if(a.level < b.level) return -1
          
          else return 0
      })

      return list

    })

    const sortedSyllableList = computed( () => {
      
      let list = [];
      if(userStore.mySyllables == null) return list

      for(let syl in userStore.mySyllables)
      {
        list.push(userStore.mySyllables[syl]);
      }

      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })

      return list
    })


    const loading = computed( () => {
      if(authStore.fullyLoaded == true) return false
      return true
    })

    const myLevel = computed( () => {
        return userStore.userData.level
    })

       
    const myRequestObject = computed( () => {
        return userStore.myRequests
    })

    const availableWordCount = computed( () => {

        let totalWords = 0;
        console.log(myLevel.value);

        for(let level in wordStore.wordCounts)
        {
          
          if(+level <= myLevel.value) totalWords += wordStore.wordCounts[level];
          console.log(level, totalWords);
        }
  
        let knownWords = 0;
        for(let word in userStore.myWords)
        {
          if(word.indexOf(" ") == -1) knownWords++;
        }

        console.log(knownWords);
  

        return totalWords - knownWords;
    })


    const newWords = computed( () => {
      let list = [];
      if(!userStore.myWords) return list
      for(let word in userStore.myWords)
      {

        if(userStore.myWords[word].isNew == true) list.push(word);        
      }

      return list
    })

    watch(newWords, () => {
      if(newWords.value.length == 0) return
      scrollTo(newWords.value[0]);
      return
  })  


 

    return {
        userStore,
        wordStore,
        authStore,
        showAppBar,
        scrollTimer,
        colors,
        loading,
        showModal,
        modalMessage,
        wordLevelRequirements,
        wordLevelColours,
        speakerDetails,
        highlightedSyllable,
        unconfirmedNewWords,
        morphemeLetterOptions,
        sortedWordList,
        sortedSyllableList,
        mode,
        myRequestObject,
        soundEffects,
        soundEffectsActivated,
        availableWordCount,
        computedClaimWordContent,
        isSafari,
        playingAudio,
        playSpeakerAudio,
        playSpeakerSyllableAudio,
        playAudioFile,
        preloadSoundEffects,
        playSoundEffect,
        confirmWord,
        unlockWordPoints,
        scrollTo,

    }
  },

  async mounted(){

    
    await this.preloadSoundEffects();
   
    
    window.addEventListener('scroll', () => {
        if(this.scrollTimer !== null) {
            clearTimeout(this.scrollTimer);        
        }
        this.showAppBar = false;
        
        this.scrollTimer = setTimeout(() => {
            this.showAppBar = true; 
        }, 250);
    }, false)


    window.addEventListener('click', () => {
      
      if(this.soundEffectsActivated == false)
      {

        for(let effect in this.soundEffects){
          
          this.soundEffects[effect].audio = new Audio();
          if(this.soundEffects[effect].src == null) continue
          this.soundEffects[effect].audio.src = this.soundEffects[effect].src.src;
          this.soundEffects[effect].audio.load();
        }   
        this.soundEffectsActivated = true;
      }
    })    

  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
  @import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@500&display=swap');


  #wordListTable {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

#wordListTable td {
  width: 250px;
  height: 20px !important;
  max-height: 20px !important;
  vertical-align: middle;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.letterHeader{
   font-family: 'Zen Maru Gothic', sans-serif;
}


.inputButton{
  border: solid .125rem rgb(37,0,2);
  border-radius: .3rem;
  height: 36px;
  line-height: 30px;
  cursor: pointer;
  color: rgb(37,0,2);
  font-weight: 600;
}


.inputButtonLarge{
  border: solid .125rem rgb(37,0,2);
  border-radius: .3rem;
  height: 115px;
  line-height: 111px;
  cursor: pointer;
  color: rgb(37,0,2);
  font-weight: 600;
  margin: 0 auto;
}

.disabled{
  opacity: .8;
  color: gray;
  border-color: gray;
}

.sroDisplay{
  font-size: 44px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  padding-bottom: 6px;
  width: 36px;
  height: 52px;
  text-align: center;
  border-bottom: 2px solid black;
}

.sylDisplay{
  font-size: 26px;
  margin-left: 1px;
  margin-right: 1px;
  width: 20px;
  text-align: center;
  cursor: pointer;
}

.highlightCell {
  color: #fa7e61
}

.slideup{
  transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);;
}

.v-enter-active{
  transition: opacity 0s ease;
}
.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from {
  opacity: 0;
}

.v-leave-to {
  opacity: 0;
}

.wordPointButton {
  animation: jiggle 3s infinite ease-in;
}

.pulseButton { 
  animation: pulse-black 2s infinite;
}

.pulseIcon { 
  animation: pulse-icon-black .75s infinite;
}

@keyframes jiggle {
  45%, 65% {
    transform: scale(1.0, 1.0)
  }
  50% {
    transform: scale(1.1, 0.8)
  }
  55% {
    transform: scale(0.8, 1.2)
  }
  60% {
    transform: scale(1.0, 1.0)
  }
}

@keyframes pulse-black {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-icon-black {
	0% {
		transform: scale(1);
	}
	
	50% {
		transform: scale(.99);
	}
	
	100% {
		transform: scale(1);
	}
}
</style>
