<template>
  <div>

    <va-modal v-model="showModal" :message="modalMessage"  />


    <div style="display: flex; justify-content: center; flex-wrap: nowrap; margin-top: 25px; margin-bottom: 25px">

     <va-button-group class="mb-4" size="medium" style="width: 90%">
      <va-button style="width: 50%;" @click="section = 'Words'" :color="section == 'Words' ? 'primary' : 'secondary'">Words</va-button>
      <va-button style="width: 50%;" @click="section = 'Morphemes'" :color="section == 'Morphemes' ? 'primary' : 'secondary'">Morphemes</va-button>
    </va-button-group>

    </div>
    

    <template v-if="section == 'Morphemes'">

      <table class="va-table wordListTable" >

        <tr>
          <th style="width: 25%; text-align: right; padding-right: 5px">SRO</th>
          <th style="width: 75%">Details</th>
        </tr>

        <tr v-for="(morpheme, m) in sortedMorphemeList" :key="'morphemeList' + m">
        <td style="text-align: right: padding-right: 5px">
          {{ morpheme.sro}}

          <div>
            <va-button 
              outline 
              :rounded="false"
              size="small"
              color="danger"
              style="width: 50%; font-size: 12px; margin: 0 auto; margin-top: 10px; padding: 5px; max-width: 500px" 
              @click="deleteMorpheme(morpheme)"
            >
              Delete
            </va-button>
          </div>

        </td>
        <td>
          <div style="display: flex; justify-content: flex-start; flex-wrap: wrap">

          <va-input
            style="width: 100%; padding: 5px;"
            @change="updateMorpheme(morpheme, 'definition')"
            v-model="morpheme.definition"
            label="English Definition"
          ></va-input>

          <va-input
            @change="updateMorpheme(morpheme, 'syllabics')"
            style="width: 100%; padding: 5px;"
            v-model="morpheme.syllabics"
            label="Syllabics"
          ></va-input>

          <va-list style="padding: 0px; text-align: left; width: 100%; padding: 5px;">

            <va-list-label style="text-align: left">
              Members
            </va-list-label>

            <va-list-item v-for="(word, w) in morpheme.words.items" :key="'wordMember' + m + w">
            <va-list-item-section>
            <va-list-item-label>
              {{ word.sro }}
            </va-list-item-label>

            </va-list-item-section>
              

            </va-list-item>

          </va-list>

          </div>
          

        </td>

        </tr>
      </table>
      



      <div style="width: 90%; margin-left: 5%">

      <h3>Add A New Morpheme</h3>

          <va-input
            style="margin-top: 5px; margin-bottom: 5px"
            v-model="newMorpheme.sro"
            name="SRO"
            label="SRO"
            placeholder=""
            @change="populateSyllabics()"
            required-mark
          ></va-input>
          <va-input
            style="margin-top: 5px; margin-bottom: 5px"   
            v-model="newMorpheme.definition"
            name="Definition"
            label="English Definition"
            placeholder=""
            required-mark
          ></va-input>

          <va-input
            style="margin-top: 5px; margin-bottom: 5px"
            v-model="newMorpheme.syllabics"
            name="Syllabics"
            label="Syllabics"
            placeholder=""
            required-mark
          ></va-input>


          <va-button outline  @click="createMorpheme()">
            Submit
          </va-button>


        </div>
    </template>

    <template v-else-if="section == 'Words'">

      <va-select
        style="margin-top: 15px; margin-left: 5%; margin-right: 5%;"
        v-model="selectedWord"
        :options="allWordOptions"
        searchable
        color="#000"
        placement="top"
        label="Word Search"
      ></va-select>

      <div style="display: flex; justify-content: flex-start; flex-wrap: wrap; width: 90%; margin-left: 5%; margin-right: 5%; margin-top: 15px; margin-bottom: 10px">

    

          <div style="width: 36px; min-width: 36px; margin-left: 1.25%; margin-right: 1.25%" v-for="(letter,l) in morphemeLetterOptions" :key="'letterFilterCell' +l">


            <va-button 
              v-on:click="filterWordListLetter = letter"
              :outline="filterWordListLetter == letter ? false : true"
              size="small"
              color="#000"
              :rounded="false"              
              style="width: 100%; font-size: 14px;"
              :key="'filterButton' + l"
            >          
              {{ letter }}
              
            </va-button>

          </div>
        </div>


      <va-select
        style="margin-top: 15px; margin-left: 5%; margin-right: 5%;"
        v-model="selectedMorpheme"
        :options="morphemeOptions_filtered"
        searchable
        color="#000"
        placement="top"
        label="Morpheme Group"
      ></va-select>

      <table class="va-table wordListTable" v-if="sortedWordList.length > 0">

        <tr>
          <th style="width: 25%; text-align: right; padding-right: 5px">SRO</th>
          <th style="width: 75%">Details</th>
        </tr>

        <tr v-for="(word, w) in sortedWordList" :key="'wordList' + w">
          <td style="text-align: right; padding-top: 15px; padding-right: 5px; font-weight: bold">
                 {{ word.sro }}
          </td>
          <td>
            <div style="display: flex; justify-content: flex-start; flex-wrap: wrap">
            <va-input
              style="width: 100%; padding: 5px; border: 1px solid black;"
              @change="updateWord(word, 'definition')"
              v-model="word.definition"
              label="English Definition"
            ></va-input>

            <va-input
              @change="updateWord(word, 'syllabics')"
              style="width: 100%; padding: 5px; border: 1px solid black;"
              v-model="word.syllabics"
              label="Syllabics"
            ></va-input>

            <div style="width: 100%; padding: 5px;">

            <va-select
                @update:model-value="updateWord(word, 'morphemeWordsId')"
                v-model="word.morphemeWordsId"
                style="width: 100%; padding: 5px; border: 1px solid black;"
                :options="morphemeOptions"
                searchable
                label="Morpheme Group"
              />
            </div>

            <div style="width: 50%; padding: 5px;">

            <va-input
                style="width: 100%; padding: 5px; border: 1px solid black;"
                @update:model-value="updateWord(word, 'level')"
                v-model="word.level"
                label="Word Level"
              />
            </div>


            <div style="width: 50%; padding: 5px;">
          
              <va-select
                @update:model-value="updateWord(word, 'type')"
                v-model="word.type"
                :options="typeOptions"
                label="Word Type"
                style="width: 100%; padding: 5px; border: 1px solid black;"
              />
            </div>

            <div style="width: 100%; padding: 5px;">
              <va-input
                  @update:model-value="updateWord(word, 'dependencies')"
                  v-model="word.dependencies"
                  label="Dependencies"
                  style="width: 100%; padding: 5px; border: 1px solid black;"
                />
            </div>

            <div style="width: 100%; padding: 5px;">
              <va-select
                style="margin-top: 15px; margin-left: 5%; margin-right: 5%; border: 1px solid black;"
                :options="sortedWordList_complete_sro"
                searchable
                placement="top"
                v-model="dependencyOptions[w]"
                @update:model-value="addDependencyToWord(word, w)"
                label="Add Dependency"
                
              ></va-select>

            <div style="width: 100%; padding: 5px;">
              <va-input
                  type="textarea"
                  autosize
                  @update:model-value="updateWord(word, 'notes')"
                  v-model="word.notes"
                  label="Notes"
                  style="width: 100%; padding: 5px; border: 1px solid black;"
                />
            </div>

            </div>


  
            <va-button 
              outline 
              style="width: 90%; margin: 0 auto; padding: 5px; max-width: 500px" 
              @click="createMorphemeFromWord(word)"
            >
              Make Morpheme Group
            </va-button>

              
            <va-button 
              outline 
              
              color="danger"
              style="width: 90%; margin: 0 auto; padding: 5px; max-width: 500px" 
              @click="deleteWord(word)"
            >
              Delete Word
            </va-button>

            </div>
          </td>
        </tr>

      </table>
      



      <div style="width: 90%; margin-left: 5%">

      <h3>Add A New Word</h3>

        <div style="margin-bottom: 25px; width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap">

          <va-input
            style="margin-top: 5px; margin-bottom: 5px; width: 100%"
            v-model="newWord.sro"
            name="SRO"
            label="SRO"
            color="#000"
            placeholder=""
            @change="populateSyllabics()"
            required-mark
          ></va-input>
          <va-input
            style="margin-top: 5px; margin-bottom: 5px; width: 100%"   
            v-model="newWord.definition"
            name="Definition"
            label="English Definition"
            color="#000"
            placeholder=""
            required-mark
          ></va-input>

          <va-input
            style="margin-top: 5px; margin-bottom: 5px; width: 100%"
            v-model="newWord.syllabics"
            name="Syllabics"
            label="Syllabics"
            color="#000"
            placeholder=""
            required-mark
          ></va-input>

          <va-select
            style="margin-top: 5px; margin-bottom: 5px; width: 48%"
            v-model="newWord.type"
            name="Word Type"
            label="Word Type"
            :options="typeOptions"
            color="#000"
            placeholder=""
            required-mark
          ></va-select>

          

          <va-select
            style="margin-top: 5px; margin-bottom: 5px; width: 48%"
            v-model="newWord.morphemeWordsId"
            :options="morphemeOptions"
            searchable
            color="#000"
            label="Morpheme Group"
          ></va-select>

          <va-input
            style="margin-top: 5px; margin-bottom: 5px; width: 48%"
            v-model="newWord.level"
            color="#000"
            label="Word Level"
          ></va-input>

          
          <va-input
            style="margin-top: 5px; margin-bottom: 5px; width: 100%"
            v-model="newWord.dependencies"
            color="#000"
            label="Dependencies"
          ></va-input>

          <va-select
               style="margin-top: 5px; margin-bottom: 5px; width: 48%"
              :options="sortedWordList_complete_sro"
              searchable
              color="#000"
              placement="top"
              v-model="newWordDependencyOption"
              @update:model-value="addDependencyToNewWord()"
              label="Add Dependency"
            ></va-select>

             <va-button outline :rounded="false"  @click="createWord()"  color="black" style="margin-top: 5px; margin-bottom: 5px">           
            Submit
          </va-button>

        </div>


        </div>
      </template>


      <va-divider />


      <div style="display: flex; justify-content: flex-start; flex-wrap: nowrap; width: 85%; margin-left: 7.5%; margin-right: 7.5%; margin-top: 5px; margin-bottom: 5px;">
      
        <div style="width: 10%; margin-left: 1.25%; margin-right: 1.25%">
            <div
                class="inputButton"
                style="width: 100%; font-size: 18px; color: #250902; border-color: #250902; padding: 0px;"
                @click="backspace()"
              > <va-icon name="keyboard_backspace" color="#250902"></va-icon>
            </div>
        </div>
        

        <div v-for="i in 1" style="width: 10%; margin-left: 1.25%; margin-right: 1.25%" :key="'cwvspacer ' + i">

      </div>


        <div style="width: 10%; margin-left: 1.25%; margin-right: 1.25%">
          <div
                  class="inputButton"
                  style="width: 100%; font-size: 18px; color: #250902; border-color: #250902; padding: 0px;"
                  @click="clear()"
                > <va-icon name="clear" color="#250902"></va-icon>
              </div>
        </div>

        <div v-for="i in 1" style="width: 10%; margin-left: 1.25%; margin-right: 1.25%" :key="'cwvspacer ' + i">

        </div>

                      
        <div style="width: 10%; margin-left: 1.25%; margin-right: 1.25%">
                <va-button 
                  outline
                  :rounded="false"
                  style="width: 100%; font-size: 18px; color: #250902; border-color: #250902"
                  @click="addSpace()"
                >  {{ '_' }}
                </va-button>
                
        </div>

        <div style="width: 10%; margin-left: 1.25%; margin-right: 1.25%">
          <va-button 
            outline
            :rounded="false"
            style="width: 100%; font-size: 18px; color: #250902; border-color: #250902"
            @click="addHyphen()"
          >  {{ '-' }}
          </va-button>
                
        </div>

        <div v-for="i in 1" style="width: 10%; margin-left: 1.25%; margin-right: 1.25%" :key="'cwvspacer ' + i">

      </div>

        <div style="width: 10%; margin-left: 1.25%; margin-right: 1.25%">
                <va-button 
                  :outline="showCwV == false"
                  :rounded="false"
                  style="width: 100%; font-size: 18px; color: #250902; border-color: #250902"
                  @click="showCwV = !showCwV"
                > 
                <div style="display: flex; flex-wrap: nowrap; align-items: baseline">
                 <div style="color: rgba(137,137,137,1); font-size: 14px; line-height: 18px; height: 18px;">c</div><div style="font-size: 18px; line-height: 18px; height: 18px">w</div><div style="color: rgba(137,137,137,1); font-size: 14px; line-height: 18px; height: 18px">v</div>
                 </div>
                </va-button>
        </div>
        
      </div>


      <va-divider />

    
    
        <div v-for="(row, r) in syllabics" :key="'syllableRow' + r" style="display: flex; justify-content: flex-start; flex-wrap: nowrap; width: 85%; margin-left: 7.5%; margin-right: 7.5; margin-bottom: 5px">

        
          <div style="width: 10%; margin-left: 1.25%; margin-right: 1.25%" v-for="(col, c) in row" :key="'syllableCell' + r + c">

          <va-hover stateful>
            <template #default="{ hover }">
            
              <div 
                v-if="inputMode == 'Syllabary'"

                v-on:mousedown="startCwVCounter(c); activeSyllable = r + c"
                v-on:mouseup="addSyllable(c, col.sro, col.CwV,c);"
                v-on:mouseleave="stopCwVCounter(c);"
                :style="{ fontSize: ((showCwV || (CwVCounter > 0 && col.CwV == true)) && (activeSyllable == (r + c) || hover == true)) ? '18px' : '20px' }"
                class="inputButton"
                style="width: 100%; color: rgb(109, 66, 71); border-color: rgb(109, 66, 71); padding: 0px;"
                :key="'syllableButton' + r + c + 's'"
              >     
       
                 {{ hover == false ? (CwVCounter > 0 && col.CwV == true && activeSyllable == (r + c)) ? getCwVForm(col.sro) : activeSyllable == (r + c) ? (showCwV == true && col.CwV == true) ? getCwVForm(col.sro) : col.sro : (showCwV == true && col.CwV == true) ? c + 'ᐧ' : c : (CwVCounter > 0 && col.CwV == true) ? getCwVForm(col.sro) : (showCwV == true && col.CwV == true) ? getCwVForm(col.sro) : col.sro  }}
         
              </div>

              <div 
                v-else-if="inputMode == 'Orthography' && col != ''"

                v-on:mousedown="startCwVCounter(c)"
                v-on:mouseup="addSRO(col.sro, col.CwV,c)"
                v-on:mouseleave="stopCwVCounter(c)"
                class="inputButton"
                style="width: 100%; color: rgb(109, 66, 71); border-color: rgb(109, 66, 71); padding: 0px;"
                :style="{ fontSize: ((showCwV && col.CwV == true) || (CwVCounter > 0 && col.CwV == true)) ? '14px' : '18px' }"
                :key="'syllableButton' + r + c + 'o'"
              >
  
             
                  {{ hover == false ? ((showCwV && col.CwV == true) || CwVCounter > 0 && col.CwV == true) ? getCwVForm(col.sro) : col.sro : (CwVCounter > 0 && col.CwV == true) ? c + 'ᐧ' : c }}
           
                
                </div>

              
            </template>
          </va-hover>
        
          </div>

        </div>

        
        <div style="display: flex; justify-content: center; flex-wrap: nowrap; margin-top: 25px; margin-bottom: 15px">

          <div style="margin: 5px 10px 5px 10px; font-size: 12px; line-height: 24px; height: 24px; font-weight: bold;" v-bind:style="{ color: inputMode == 'Syllabary' ? 'rgb(109, 66, 71)' : 'grey' }">Orthography</div>
          <va-switch style="margin: 5px 10px 5px 10px; height: 24px" v-model="inputMode" size="small" false-value="Orthography" true-value="Syllabary" off-color="primary"/>
          <div style="margin: 5px 10px 5px 10px; font-size: 12px; line-height: 24px; height: 24px; font-weight: bold;" v-bind:style="{ color: inputMode == 'Orthography' ? 'rgb(109, 66, 71)' : 'grey' }">Syllabary</div>
          
        </div>

    
   

        <va-divider />


 
  </div>


</template>

<script>



import { useAuth } from '@/stores/Auth/auth'
import { useWords } from '@/stores/Words/words'
import { reactive, ref, computed, watch } from 'vue';
import IPA from "@/assets/ipa.js";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useColors } from 'vuestic-ui';




export default {
  name: 'AdminPage',
  components: {
  },
  props: {
  },

  setup(){


    const { getColors } = useColors()
    const colors = computed(getColors)


    const authStore = useAuth();
    const wordStore = useWords();

    wordStore.listAllWords();
    wordStore.listAllMorphemes();
    wordStore.listAllSyllables();

    const syllabics = reactive(IPA.syllabics);
    const inputMode = ref("Orthography");
    const section = ref("Words");
    const selectedMorpheme = ref("");
    const selectedWord = ref("");



    const filterWordListLetter = ref("");

    const dependencyOptions = ref([])
    const newWordDependencyOption = ref(null)


    watch(filterWordListLetter, async () => {
      selectedMorpheme.value = morphemeOptions_filtered.value[0];
    })

    const activeSyllable = ref(null); 
    const showCwV = ref(false);

    
    const morphemeLetterOptions = ref([
      'a','ā','c','ē','i','ī','k','m','n','o','ō','p','s','t','w','y'
    ])


    let showModal = false;
    let modalMessage = null;
    let updatingWord = false;
    let updatingMorpheme = false;

    const CwVCounter = ref(0);
    let CwVInterval = null;

    const newWord = reactive({
      sro: null,
      definition: null,
      type: null,
      syllabics: null,
      morphemeWordsId: null,
      level: null,
    });

    const newMorpheme = reactive({
      sro: null,
      definition: null,
      syllabics: null,
    });

    const types = reactive({
      "IPC": { name: "Independent particle", Description: "Often specifying the time, place, manner etc of a verb" },
      "IPH": { name:  "Set particle phrase", Description: "" },
      "IPV": { name:  "Preverb", Description: "" },
      "IPN": { name:  "Prenoun", Description: "" },
      "IPP": { name:  "Pre-particle", Description: "" },
      "NA": { name:  "Animate noun", Description: "" },
      "NI": { name:  "Inaminate noun", Description: "" },
      "INM": { name:  "Indeclinable nominal", Description: "" },
      "NDA": { name:  "Animate depednent noun", Description: "" },
      "NDI": { name:  "Inanimate dependent noun", Description: ""},
      "PR": { name:  "Pronoun", Description: "" },
      "VII": { name:  "Inanimate intransitive verb", Description: "" },
      "VAI": { name:  "Animate intransitive verb", Description: "" },
      "VTI": { name:  "Inanimate transitive verb", Description: "" },
      "VTA": { name:  "Animate transitive verb", Description: "" },
      "VAIt": { name:  "", Description: "" },
    });

    const typeOptions = [
      'IPC','IPH','IPV','IPN','IPP','NA','NI','NDA','NDI','PR','VII','VAI','VTI','VTA','VAIt'
    ];

    const allWordOptions = computed( () => {

      let list = [];
      if(wordStore.words == null) return list
      for(let word in wordStore.words)
      {
        list.push(word);
      }
      
      return list

    })


    const morphemeOptions = computed( () =>{

      let list = [];
      if(wordStore.morphemes == null) return list

      for(let morpheme in wordStore.morphemes)
      {
        list.push(wordStore.morphemes[morpheme].sro);
      }

      list.sort(function(a,b){
        if(a > b) return 1
        else if(a < b) return -1
        else return 0
      })

      list.push("none");
      list.unshift("null");
      return list
    });

    const morphemeOptions_filtered = computed( () =>{

      let list = [];
      if(wordStore.morphemes == null) return list

      for(let morpheme in wordStore.morphemes)
      {
        if(filterWordListLetter.value != "" && wordStore.morphemes[morpheme].sro[0] != filterWordListLetter.value) continue
        list.push(wordStore.morphemes[morpheme].sro);
      }

      list.sort(function(a,b){
        if(a > b) return 1
        else if(a < b) return -1
        else return 0
      })

      list.push("none");
      list.unshift("null");
      
      return list
    })

    const sortedWordList = computed( () => {
      
      let list = [];
      if(wordStore.words == null) return list

      for(let word in wordStore.words)
      {
        if(word == selectedWord.value){
          list.push(wordStore.words[word]);
        }
        else if(selectedMorpheme.value == "none" && word[0] == filterWordListLetter.value)
        {
          list.push(wordStore.words[word]);
        }
        else if(wordStore.words[word].morphemeWordsId == selectedMorpheme.value)
        {
          list.push(wordStore.words[word]);
        }
       

        
      }

      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })

      return list

    })

    
    const sortedWordList_complete_sro = computed( () => {
      
      let list = [];
      if(wordStore.words == null) return list

      for(let word in wordStore.words)
      {
        list.push(wordStore.words[word].sro);
      }

      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })

      return list

    })

    
    const sortedMorphemeList = computed( () => {
      
      let list = [];
      if(wordStore.morphemes == null) return list

      for(let morpheme in wordStore.morphemes)
      {
        list.push(wordStore.morphemes[morpheme]);
      }

      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })

      return list

    })

    const addDependencyToWord = async (word, dependency) => {
      if(word.dependencies == null) word.dependencies = "";
      word.dependencies += dependencyOptions.value[dependency] + ";";
      updateWord(word, 'dependencies');
      dependencyOptions.value[dependency] = null;
      return
    }

    const addDependencyToNewWord = async () => {
      if(newWord.dependencies == null) newWord.dependencies = "";
      newWord.dependencies += newWordDependencyOption.value + ";";
      newWordDependencyOption.value = null;
      return
    }

    const createWord = async () => {
      if(newWord.sro == null || newWord.sro == "") return
      await wordStore.createWord(newWord)
      newWord.sro = null;
      newWord.definition= null;
      newWord.type = null;
      newWord.syllabics= null;      
      newWord.morphemeWordsId = null;
      newWord.level = null;
      newWord.dependencies = null;
      newWordDependencyOption.value = null;
    };

    const shiftWords = async (level) => {
      wordStore.shiftWords(level);

    }

     const addNotesToWords = async (level) => {
      wordStore.addNotesToWords(level);

    }



    const createMorpheme = async () => {
      await wordStore.createMorpheme(newMorpheme)
      newMorpheme.sro = null;
      newMorpheme.definition= null;
      newMorpheme.syllabics= null;      
    };

    const createMorphemeFromWord = async (word) => {
      await wordStore.createMorpheme(
        {
          sro: word.sro,
          definition: word.definition,
          syllabics: word.syllabics,
          level: 1,
          active: true,
        })

        word.morphemeWordsId = word.sro;
        updateWord(word, 'morphemeWordsId');

        return
    }


    const deleteWord = async (word) => {
      await wordStore.deleteWord(word.sro);
    }

    const updateWord = async (word, field) => {
      if(updatingWord == true) return
      updatingWord = true;

      await new Promise(resolve => setTimeout(resolve, 50));

      await wordStore.updateWord({
        sro: word.sro,
        [field]: word[field],
      })
       updatingWord = false;
    }

    const updateMorpheme = async (morpheme, field) => {
      if(updatingMorpheme == true) return
      updatingMorpheme = true;

      await wordStore.updateMorpheme({
        sro: morpheme.sro,
        [field]: morpheme[field],
      })
       updatingMorpheme = false;
    }

    
    const deleteMorpheme = async (morpheme) => {
      await wordStore.deleteMorpheme(morpheme.sro);
    }

    const startCwVCounter = () => {
      CwVInterval = setInterval(()=>{
        CwVCounter.value += .25;
      },250)
    }

    const stopCwVCounter = () => {
      clearInterval(CwVInterval);
      CwVCounter.value = 0;
      window.focus();

    }

    const addSyllable = (col, sro, CwV ) => {
      
     

      if(section.value == "Words")
      {
        if(newWord.sro == null) newWord.sro = "";
        if(newWord.syllabics == null) newWord.syllabics = "";

        if((showCwV.value == true && CwV) || (CwVCounter.value > 0 && CwV == true))
        {
          newWord.sro += sro[0] + 'w' + sro[1];
          newWord.syllabics += col + 'ᐧ';

          setTimeout(()=>{
            showCwV.value = false;
          },100)
        
        }
        else{
          newWord.syllabics += col;
          newWord.sro += sro;
        }
      }
      else if(section.value == "Morphemes")
      {
        if(newMorpheme.sro == null) newMorpheme.sro = "";
        if(newMorpheme.syllabics == null) newMorpheme.syllabics = "";

        if((showCwV.value == true && CwV) || (CwVCounter.value > 0 && CwV == true))
        {
          newMorpheme.sro += sro[0] + 'w' + sro[1];
          newMorpheme.syllabics += col + 'ᐧ';

          setTimeout(()=>{
            showCwV.value = false;
          },100)
        
        }
        else{
          newMorpheme.syllabics += col;
          newMorpheme.sro += sro;
        }
      }

      setTimeout(()=>{
        activeSyllable.value = null;
      },500)
     
      stopCwVCounter();
      
    };

    const addSRO = (col, CwV ) => {

      
      if(section.value == "Words")
      {
        if(newWord.sro == null) newWord.sro = "";
        if(newWord.syllabics == null) newWord.syllabics = "";

        if((showCwV.value == true && CwV) || (CwVCounter.value > 0 && CwV == true))
        {
          newWord.sro += col[0] + 'w' + col[1];
          newWord.syllabics += getSyllableForSRO(col) + 'ᐧ';

          setTimeout(()=>{
            showCwV.value = false;
          },100)
        
        }
        else{        
          newWord.sro += col;
          newWord.syllabics += getSyllableForSRO(col);
        }
      }

      else if(section.value == "Morphemes")
      {
        if(newMorpheme.sro == null) newMorpheme.sro = "";
        if(newMorpheme.syllabics == null) newMorpheme.syllabics = "";

        if((showCwV.value == true && CwV) || (CwVCounter.value > 0 && CwV == true))
        {
          newMorpheme.sro += col[0] + 'w' + col[1];
          newMorpheme.syllabics += getSyllableForSRO(col) + 'ᐧ';

          setTimeout(()=>{
            showCwV.value = false;
          },100)
          
        }
        else{        
          newMorpheme.sro += col;
          newMorpheme.syllabics += getSyllableForSRO(col);
        }

      }

      stopCwVCounter();

    };

    const backspace = () => {

      if(section.value == "Words")
      {
        let latest_syllable = newWord.syllabics.charAt(newWord.syllabics.length - 1);

        let CwV = 0;
        if(latest_syllable == 'ᐧ'){
          latest_syllable = newWord.syllabics.charAt(newWord.syllabics.length - 2);
          CwV++;
        }
        let sro_length = getSROForSyllable(latest_syllable).length;
        
        newWord.syllabics = newWord.syllabics.substring(0,newWord.syllabics.length - (1 + CwV));
        newWord.sro = newWord.sro.substring(0,newWord.sro.length - (sro_length + CwV));
      }
      else if(section.value == "Morphemes")
      {
        let latest_syllable = newMorpheme.syllabics.charAt(newMorpheme.syllabics.length - 1);

        let CwV = 0;
        if(latest_syllable == 'ᐧ'){
          latest_syllable = newMorpheme.syllabics.charAt(newMorpheme.syllabics.length - 2);
          CwV++;
        }
        let sro_length = getSROForSyllable(latest_syllable).length;
        
        newMorpheme.syllabics = newMorpheme.syllabics.substring(0,newMorpheme.syllabics.length - (1 + CwV));
        newMorpheme.sro = newMorpheme.sro.substring(0,newMorpheme.sro.length - (sro_length + CwV));
      }
    };

    const addHyphen = () => {

      if(newWord.sro == null) newWord.sro = "";
      if(newWord.syllabics == null) newWord.syllabics = "";
      
      if(section.value == "Words")
      {
        newWord.syllabics += " ";
        newWord.sro += "-";
      }
      else if(section.value == "Morphemes")
      {
        newMorpheme.syllabics += " ";
        newMorpheme.sro += "-";
      }
    };

    const addSpace = () => {

      if(newWord.sro == null) newWord.sro = "";
      if(newWord.syllabics == null) newWord.syllabics = "";

      if(section.value == "Words")
      {
        newWord.syllabics += "  ";
        newWord.sro += " ";
      }
      else if(section.value == "Morphemes")
      {
        newMorpheme.syllabics += "  ";
        newMorpheme.sro += " ";
      }
      
    };


    const clear = () => {

      if(section.value == "Words")
      {
        newWord.syllabics = "";
        newWord.sro = "";
      }
      else if(section.value == "Morphemes")
      {
        newMorpheme.syllabics = "";
        newMorpheme.sro = "";
      }
    };

    const getSyllableForSRO = ( sro ) => {
        for(let row in syllabics)
        {
          for(let col in syllabics[row])
          {
            if(syllabics[row][col].sro == sro) return col
          }
        }
        return null
    }

    const getSROForSyllable = ( syllable ) => {
      for(let row in syllabics)
      {
        for(let col in syllabics[row])
        {
          if(col == syllable) return syllabics[row][col].sro;
        }
      }
      return null
    }

    const getCwVForm = ( sro ) => {
      if(sro.length == 2) return sro[0] + 'w' + sro[1];
      return sro
    }

    
    const populateSyllabics = () => {
      console.log(newWord.sro);
    };

    const signOut = () => {
      authStore.logout();
    }

    const playAudioFile = async (url) => {
      const client = new S3Client({ region: "ca-central-1", credentials: authStore.currentCredentials });

      const input = {
        Bucket: "nehiyawewin-polly",
        Key: url.substring(56),
        ResponseContentType: "audio/mp3",
      }

      try{
        const command = new GetObjectCommand(input);
        const signedURL = await getSignedUrl(client, command, { expiresIn: 100 });
        var a = new Audio(signedURL);
        a.play();
      } catch(err)
      {
        console.log(err);
        showModal = true;
        modalMessage = "File not found - try waiting a few seconds if this word was just created"
      }
    }


    return {
      authStore,
      wordStore,
      newWord,
      newMorpheme,
      syllabics,
      activeSyllable,
      inputMode,
      showCwV,
      section,
      selectedMorpheme,
      selectedWord,
      updatingWord,
      types,
      typeOptions,
      morphemeOptions,
      morphemeOptions_filtered,
      morphemeLetterOptions,
      filterWordListLetter,
      sortedWordList,
      sortedWordList_complete_sro,
      sortedMorphemeList,
      colors,
      showModal,
      modalMessage,
      CwVCounter,
      dependencyOptions,
      newWordDependencyOption,
      allWordOptions,
      shiftWords,
      addNotesToWords,
      startCwVCounter,
      stopCwVCounter,
      createWord,
      createMorpheme,
      createMorphemeFromWord,
      updateWord,
      deleteWord,
      deleteMorpheme,
      updateMorpheme,
      addDependencyToWord,
      addDependencyToNewWord,
      populateSyllabics,
      addSyllable,
      addSRO,
      getCwVForm,
      backspace,
      addHyphen,
      addSpace,
      clear,
      signOut,
      playAudioFile,
    }
  },

  async mounted(){

    await this.authStore.authAction();
    await this.wordStore.setupSubscriptions();

    this.selectedMorpheme = this.morphemeOptions[0];
    this.filterWordListLetter = this.morphemeLetterOptions[0];

    // this.shiftWords(57);

    // this.addNotesToWords(20);
    
  
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.wordListTable {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 90%;
}

.wordListTable th {
  text-align: center;
  padding: 5px;
}

.wordListTable td {
  text-align: center;
  padding: 10px;
  width: 250px;
  height: 50px !important;
  max-height: 50px !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.inputButton{
  border: solid .125rem rgb(37,0,2);
  border-radius: .3rem;
  height: 36px;
  line-height: 30px;
  cursor: pointer;
  color: rgb(37,0,2);
  font-weight: 600;
}
</style>

