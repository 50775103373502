import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from 'aws-amplify'
import AdminPage from '../views/Admin/AdminPage.vue'
import HomePage from '../views/Home/HomePage.vue'
import SpeakerPage from '../views/Speaker/SpeakerPage.vue'
import LeaderboardPage from '../views/Leaderboard/LeaderboardPage.vue'
import AboutPage from '../views/About/AboutPage.vue'
import ProfilePage from '../views/Profile/ProfilePage.vue'
import WordPage from '../views/Words/WordPage.vue'
import PhrasePage from '../views/Phrases/PhrasePage.vue'


const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: { requiresAuth: false, title: "kiskisi" }
  },
  {
    path: "/admin",
    name: "AdminPage",
    component: AdminPage,
    meta: { requiresAuth: true, title: "kiskisi Admin",  groups: ['Admins']  }
  },
  {
    path: "/speaker",
    name: "SpeakerPage",
    component: SpeakerPage,
    meta: { requiresAuth: true, title: "Speaker Admin",  groups: ['Admins','Speakers']  }
  },
  {
    path: "/about",
    name: "AboutPage",
    component: AboutPage,
    meta: { requiresAuth: false, title: "About kiskisi" }
  },
  {
    path: "/leaderboard",
    name: "LeaderboardPage",
    component: LeaderboardPage,
    meta: { requiresAuth: true, title: "About",  groups: ['Users','Admins','Speakers']  }
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: { requiresAuth: true, title: "My Profile",  groups: ['Users','Admins','Speakers']  }
  },
  {
    path: "/words",
    name: "WordPage",
    component: WordPage,
    meta: { requiresAuth: true, title: "kisisi: Words",  groups: ['Users','Admins','Speakers']  }
  },
  {
    path: "/phrases",
    name: "PhrasePage",
    component: PhrasePage,
    meta: { requiresAuth: true, title: "kiskisi: Phrases",  groups: ['Users','Admins','Speakers']  }
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

router.beforeEach(async (to, from)=>{
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = await Auth.currentUserInfo();

  if(to.meta.groups)
  {
    let inAuthorizedGroup = false;
    let userDetails = await Auth.currentAuthenticatedUser();
    let userGroups = userDetails.signInUserSession.accessToken.payload['cognito:groups'];
    let authorizedGroups = to.meta.groups;

    for(let group of authorizedGroups)
    {
      if(userGroups.includes(group)) inAuthorizedGroup = true;
    }


    if(requiresAuth && (!isAuthenticated || !inAuthorizedGroup) && from.name !== 'Home')
    {
      return '/'
    }
    

  }

})




export default router
