export const createUserWord = /* GraphQL */ `
  mutation CreateUserWord(
    $input: CreateUserWordInput!
    $condition: ModelUserWordConditionInput
  ) {
    createUserWord(input: $input, condition: $condition) {
      sro
      userID
      audio {
        bucket
        region
        key
      }
      userWordsId
    }
  }
`;
export const updateUserWord = /* GraphQL */ `
  mutation UpdateUserWord(
    $input: UpdateUserWordInput!
    $condition: ModelUserWordConditionInput
  ) {
    updateUserWord(input: $input, condition: $condition) {
      sro
      userID
      user_confirmed
      audio {
        bucket
        region
        key
      }
      userWordsId
    }
  }
`;
export const deleteUserWord = /* GraphQL */ `
  mutation DeleteUserWord(
    $input: DeleteUserWordInput!
    $condition: ModelUserWordConditionInput
  ) {
    deleteUserWord(input: $input, condition: $condition) {
      sro
      userID
      audio {
        bucket
        region
        key
      }
      userWordsId
    }
  }
`;

export const createSpeakerWord = /* GraphQL */ `
  mutation CreateSpeakerWord(
    $input: CreateSpeakerWordInput!
    $condition: ModelSpeakerWordConditionInput
  ) {
    createSpeakerWord(input: $input, condition: $condition) {
      sro
      userID
      audio {
        bucket
        region
        key
      }
      userSpeaker_wordsId
    }
  }
`;
export const updateSpeakerWord = /* GraphQL */ `
  mutation UpdateSpeakerWord(
    $input: UpdateSpeakerWordInput!
    $condition: ModelSpeakerWordConditionInput
  ) {
    updateSpeakerWord(input: $input, condition: $condition) {
      sro
      userID
      audio {
        bucket
        region
        key
      }
      userSpeaker_wordsId
    }
  }
`;


export const deleteSpeakerWord = /* GraphQL */ `
  mutation DeleteSpeakerWord(
    $input: DeleteSpeakerWordInput!
    $condition: ModelSpeakerWordConditionInput
  ) {
    deleteSpeakerWord(input: $input, condition: $condition) {
      sro
      userID
      audio {
        bucket
        region
        key
      }
      userSpeaker_wordsId
    }
  }
`;

export const createSpeakerSyllable = /* GraphQL */ `
  mutation CreateSpeakerSyllable(
    $input: CreateSpeakerSyllableInput!
    $condition: ModelSpeakerSyllableConditionInput
  ) {
    createSpeakerSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const updateSpeakerSyllable = /* GraphQL */ `
  mutation UpdateSpeakerSyllable(
    $input: UpdateSpeakerSyllableInput!
    $condition: ModelSpeakerSyllableConditionInput
  ) {
    updateSpeakerSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;

export const updateUserRequest = /* GraphQL */ `
  mutation UpdateUserRequest(
    $input: UpdateUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    updateUserRequest(input: $input, condition: $condition) {
      userID
      unlock_words
      unlock_phrase
      confirm_word
      submit_answer
      answer_string
      next_session
      end_session
      createdAt
      updatedAt
      userRequestUserId
      request_hint
      request_hint_working_answer
    }
  }
`;