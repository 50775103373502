<template>
  <div style="text-align: left; padding: 25px; line-height: 20px; margin-top: 25px; margin-bottom: 80px; padding-bottom: 75px;">

    <va-modal v-model="showModal" :message="modalMessage"  />

    <p><strong>Developer's Statement</strong></p>
    <br>
    <p>I created kiskisi to give my children and the following generations easier access to learn and understand their ancestral language and culture.</p>
    <br>
    <p>My daughters' grandfather grew up at Red Pheasant First Nation in Saskatchewan and was immersed in Plains Cree as a child. Nonetheless, as is the case for many families, maintaining a strong connection to ancestral history, culture, and language requires a concerted effort.</p>
    <br>
    <p> kiskisi will be here for them when they are ready to learn the language and for them to hear the voice of their grandfather and of others who came before them.</p>
    <br>
    <p>The app was designed to provide a paced introduction to Plains Cree, beginning with simple words and phrases and taking the user on a journey towards comfortably reading, spelling, and listening to complex sentences.</p>
    <br>
    <p>This app is free for everyone to use. There is no intention of charging for access, although donations or sponsorship may be considered to help cover costs and enable development of new features and content.</p>
    <br>
    <p>The vision for kiskisi is for it to be constantly developed with new vocabulary, sentences, audio, learning modes, and hopefully entire texts and stories available to learners. If you are interested in helping, drop me a line using the email address below.</p>
    <br>
    <p><i>- Ian Clark</i></p>
    <br><br>
   <p><strong>Beta Test</strong></p>
    <br>
    <p>kiskisi is currently entering Beta 1 with a very limited number of invited testers. If you are reading this, that's you!</p>
    <br>
    <p>I need help to make the vision of kiskisi a reality.</p>
    <br>
    <p>Feedback on content, correctness of Plains Cree language, user experience, and design will be necessary to bring this app to general availability.</p>
    <br>
    <p>It will be critical before public access that all of the language being taught is absolutely correct and that the learning experience is both fun and effective. As I am a learner myself, input from experienced speakers of Plains Cree is highly valued.</p>
    <br>
    <p>Throughout the test please report any bugs, comments, questions, feature requests, suggestions, etc to <a href="mailto: info@kiskisi.com">info@kiskisi.com</a></p>

    </div>
    

</template>

<script>



import { ref, computed } from 'vue';
import { useColors } from 'vuestic-ui';



export default {
  name: 'AboutPage',
  components: {
  },
  props: {
  },

  setup(){


    const { getColors } = useColors()
    const colors = computed(getColors)

    const showModal = ref(false);
    const modalMessage = ref(null);



    return {
        colors,
        showModal,
        modalMessage

    }
  },

  async mounted(){

  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
#wordListTable {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

#wordListTable td {
  width: 250px;
  height: 20px !important;
  max-height: 20px !important;
  vertical-align: middle;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
