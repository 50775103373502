export const listMorphemes = /* GraphQL */ `
  query ListMorphemes(
    $sro: String
    $filter: ModelMorphemeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMorphemes(
      sro: $sro
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        definition
        syllabics
        active
        level
        words {
            items{
                sro
                definition
                type
                syllabics
                level
                dependencies
                notes
            }   
        }
      }
      nextToken
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_name
      name
      location
      speaker
      requesting_speaker
      xp
      level
      defaultSpeakerID
      trainingInstance {
        definition
        num_syllables
        num_letters
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        word_xp_awarded
        user_xp_awarded
        time_bonus
        syllabics_bonus
        clue_audio_bucketID
	      clue_audio_key
        clue_audio_user_id
        word_level
        component_results
        hints
        type
      }
      

    }
  }
`;

export const userWordByUserID = /* GraphQL */ `
  query UserWordByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userWordByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        level
        xp
        user_confirmed
        audio {
          bucket
          region
          key
        }
        userWordsId
      }
      nextToken
    }
  }
`;

export const speakerWordByUserID = /* GraphQL */ `
  query SpeakerWordByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpeakerWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    speakerWordByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        audio {
          bucket
          region
          key
        }
        userSpeaker_wordsId
      }
      nextToken
    }
  }
`;

export const userSyllableByUserID = /* GraphQL */ `
  query UserSyllableByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSyllableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSyllableByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        audio {
          bucket
          region
          key
        }
        userSyllablesId
      }
      nextToken
    }
  }
`;

export const getUserWord = /* GraphQL */ `
  query GetUserWord($sro: String!, $userID: String!) {
    getUserWord(sro: $sro, userID: $userID) {
      sro
      userID
      bucketID
      level
      xp
      user_confirmed
      createdAt
      updatedAt
      userWordsId
    }
  }
`;

export const getUserWordPoint = /* GraphQL */ `
  query GetUserWordPoint($userID: String!) {
    getUserWordPoint(userID: $userID) {
      userID
      points
      createdAt
      updatedAt
      userWordPointUserId
    }
  }
`;

export const getUserRequest = /* GraphQL */ `
  query GetUserRequest($userID: String!) {
    getUserRequest(userID: $userID) {
      userID
      unlock_words
      unlock_phrase
      confirm_word
      submit_answer
      answer_string
      next_session
      end_session
      createdAt
      updatedAt
      userRequestUserId
      change_name
      change_location
      request_speaker_access
      request_hint
    }
  }
`;

export const getWord = /* GraphQL */ `
  query GetWord($sro: String!) {
    getWord(sro: $sro) {
      sro
      definition
      type
      syllabics
      level
      dependencies
      notes
      morpheme {
        sro
        definition
        level
        syllabics
        words {
          items{
            sro
            level
            syllabics
            dependencies
            notes
          }
          nextToken
          
        }
      }
    }
  }
`;

export const getSpeakerWord = /* GraphQL */ `
query GetSpeakerWord($sro: String!, $userID: String!) {
  getSpeakerWord(sro: $sro, userID: $userID) {
    sro
    userID
    bucketID
    user {
      id
      user_name
      name
      location
    }
    audio {
      bucket
      region
      key
    }
  }
}
`;

export const listSpeakerWords = /* GraphQL */ `
  query ListSpeakerWords(
    $sro: String
    $userID: ModelStringKeyConditionInput
    $filter: ModelSpeakerWordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSpeakerWords(
      sro: $sro
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
        }
        audio {
          bucket
          region
          key
        }
      }
      nextToken
    }
  }
`;

export const getSpeakerSyllable = /* GraphQL */ `
  query GetSpeakerSyllable($sro: String!, $userID: String!) {
    getSpeakerSyllable(sro: $sro, userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
      }
      audio {
        bucket
        region
        key
      }
    }
  }
`;
export const listSpeakerSyllables = /* GraphQL */ `
  query ListSpeakerSyllables(
    $sro: String
    $userID: ModelStringKeyConditionInput
    $filter: ModelSpeakerSyllableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSpeakerSyllables(
      sro: $sro
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
        }
        audio {
          bucket
          region
          key
        }
      }
      nextToken
    }
  }
`;

export const speakerSyllableByUserID = /* GraphQL */ `
  query SpeakerSyllableByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpeakerSyllableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    speakerSyllableByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        audio {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        userSpeaker_syllablesId
      }
      nextToken
    }
  }
`;

export const getTrainingInstance = /* GraphQL */ `
  query GetTrainingInstance($userID: String!) {
    getTrainingInstance(userID: $userID) {
      userID
      definition
      num_syllables
      num_letters
      status
      createdAt
      updatedAt
      availableSyllables
      trainingInstanceAnswerId
      trainingInstanceUserId
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      clue_audio_bucketID
	    clue_audio_key
      clue_audio_user_id
      word_level
      component_results
      space_locations
      hints
      type
    }
  }
`;

export const listUserLevels = /* GraphQL */ `
  query ListUserLevels(
    $userID: String
    $level: ModelIntKeyConditionInput
    $filter: ModelUserLevelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserLevels(
      userID: $userID
      level: $level
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        level
        intro_complete
      }
      nextToken
    }
  }
`;