/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSyllable = /* GraphQL */ `
  query GetSyllable($sro: String!) {
    getSyllable(sro: $sro) {
      sro
      syllable
      CwV
      createdAt
      updatedAt
    }
  }
`;
export const listSyllables = /* GraphQL */ `
  query ListSyllables(
    $sro: String
    $filter: ModelSyllableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSyllables(
      sro: $sro
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        syllable
        CwV
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const syllableBySyllable = /* GraphQL */ `
  query SyllableBySyllable(
    $syllable: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSyllableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    syllableBySyllable(
      syllable: $syllable
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        syllable
        CwV
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWord = /* GraphQL */ `
  query GetWord($sro: String!) {
    getWord(sro: $sro) {
      sro
      definition
      type
      syllabics
      num_syllables
      morpheme {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      level
      dependencies
      notes
      image_url
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const listWords = /* GraphQL */ `
  query ListWords(
    $sro: String
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWords(
      sro: $sro
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        definition
        type
        syllabics
        num_syllables
        morpheme {
          sro
          definition
          syllabics
          level
          active
          createdAt
          updatedAt
        }
        level
        dependencies
        notes
        image_url
        createdAt
        updatedAt
        morphemeWordsId
      }
      nextToken
    }
  }
`;
export const wordByLevel = /* GraphQL */ `
  query WordByLevel(
    $level: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordByLevel(
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        definition
        type
        syllabics
        num_syllables
        morpheme {
          sro
          definition
          syllabics
          level
          active
          createdAt
          updatedAt
        }
        level
        dependencies
        notes
        image_url
        createdAt
        updatedAt
        morphemeWordsId
      }
      nextToken
    }
  }
`;
export const getWordCount = /* GraphQL */ `
  query GetWordCount($level: Int!) {
    getWordCount(level: $level) {
      level
      count
      createdAt
      updatedAt
    }
  }
`;
export const listWordCounts = /* GraphQL */ `
  query ListWordCounts(
    $level: Int
    $filter: ModelWordCountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWordCounts(
      level: $level
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        level
        count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMorpheme = /* GraphQL */ `
  query GetMorpheme($sro: String!) {
    getMorpheme(sro: $sro) {
      sro
      definition
      syllabics
      level
      active
      words {
        items {
          sro
          definition
          type
          syllabics
          num_syllables
          level
          dependencies
          notes
          image_url
          createdAt
          updatedAt
          morphemeWordsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMorphemes = /* GraphQL */ `
  query ListMorphemes(
    $sro: String
    $filter: ModelMorphemeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMorphemes(
      sro: $sro
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const morphemeByLevel = /* GraphQL */ `
  query MorphemeByLevel(
    $level: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelMorphemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    morphemeByLevel(
      level: $level
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_name
      name
      location
      speaker
      requesting_speaker
      words {
        items {
          sro
          userID
          bucketID
          level
          xp
          user_confirmed
          recent_attempt_quantity
          recent_attempt_percentage
          recent_attempt_history
          createdAt
          updatedAt
          userWordsId
        }
        nextToken
      }
      speaker_words {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_wordsId
        }
        nextToken
      }
      syllables {
        items {
          sro
          userID
          bucketID
          user_confirmed
          createdAt
          updatedAt
          userSyllablesId
        }
        nextToken
      }
      speaker_syllables {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_syllablesId
        }
        nextToken
      }
      level
      xp
      wordPoints {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        points
        createdAt
        updatedAt
        userWordPointUserId
      }
      requests {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        unlock_words
        unlock_phrase
        confirm_word
        set_default_speaker
        start_training
        submit_answer
        answer_string
        next_session
        end_session
        change_name
        change_location
        request_speaker_access
        request_hint
        request_hint_working_answer
        createdAt
        updatedAt
        userRequestUserId
      }
      level_status {
        items {
          userID
          level
          intro_complete
          createdAt
          updatedAt
          userLevel_statusId
        }
        nextToken
      }
      defaultSpeakerID
      trainingInstance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      most_recent_word
      createdAt
      updatedAt
      userWordPointsId
      userRequestsId
      userTrainingInstanceId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      nextToken
    }
  }
`;
export const getUserWordPoint = /* GraphQL */ `
  query GetUserWordPoint($userID: String!) {
    getUserWordPoint(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      points
      createdAt
      updatedAt
      userWordPointUserId
    }
  }
`;
export const listUserWordPoints = /* GraphQL */ `
  query ListUserWordPoints(
    $userID: String
    $filter: ModelUserWordPointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserWordPoints(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        points
        createdAt
        updatedAt
        userWordPointUserId
      }
      nextToken
    }
  }
`;
export const getUserRequest = /* GraphQL */ `
  query GetUserRequest($userID: String!) {
    getUserRequest(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      unlock_words
      unlock_phrase
      confirm_word
      set_default_speaker
      start_training
      submit_answer
      answer_string
      next_session
      end_session
      change_name
      change_location
      request_speaker_access
      request_hint
      request_hint_working_answer
      createdAt
      updatedAt
      userRequestUserId
    }
  }
`;
export const listUserRequests = /* GraphQL */ `
  query ListUserRequests(
    $userID: String
    $filter: ModelUserRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserRequests(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        unlock_words
        unlock_phrase
        confirm_word
        set_default_speaker
        start_training
        submit_answer
        answer_string
        next_session
        end_session
        change_name
        change_location
        request_speaker_access
        request_hint
        request_hint_working_answer
        createdAt
        updatedAt
        userRequestUserId
      }
      nextToken
    }
  }
`;
export const getTrainingInstance = /* GraphQL */ `
  query GetTrainingInstance($userID: String!) {
    getTrainingInstance(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      definition
      num_syllables
      num_letters
      word_level
      answer {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      component_results
      clue_audio_bucketID
      clue_audio_key
      clue_audio_user_id
      space_locations
      hints
      type
      createdAt
      updatedAt
      trainingInstanceAnswerId
      trainingInstanceUserId
    }
  }
`;
export const listTrainingInstances = /* GraphQL */ `
  query ListTrainingInstances(
    $userID: String
    $filter: ModelTrainingInstanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainingInstances(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      nextToken
    }
  }
`;
export const getTrainingInstanceAnswer = /* GraphQL */ `
  query GetTrainingInstanceAnswer($instanceID: String!) {
    getTrainingInstanceAnswer(instanceID: $instanceID) {
      instanceID
      answer
      instance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      createdAt
      updatedAt
      trainingInstanceAnswerInstanceId
    }
  }
`;
export const listTrainingInstanceAnswers = /* GraphQL */ `
  query ListTrainingInstanceAnswers(
    $instanceID: String
    $filter: ModelTrainingInstanceAnswerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainingInstanceAnswers(
      instanceID: $instanceID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      nextToken
    }
  }
`;
export const getUserWord = /* GraphQL */ `
  query GetUserWord($sro: String!, $userID: String!) {
    getUserWord(sro: $sro, userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      level
      xp
      user_confirmed
      recent_attempt_quantity
      recent_attempt_percentage
      recent_attempt_history
      createdAt
      updatedAt
      userWordsId
    }
  }
`;
export const listUserWords = /* GraphQL */ `
  query ListUserWords(
    $sro: String
    $userID: ModelStringKeyConditionInput
    $filter: ModelUserWordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserWords(
      sro: $sro
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        level
        xp
        user_confirmed
        recent_attempt_quantity
        recent_attempt_percentage
        recent_attempt_history
        createdAt
        updatedAt
        userWordsId
      }
      nextToken
    }
  }
`;
export const userWordByUserID = /* GraphQL */ `
  query UserWordByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userWordByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        level
        xp
        user_confirmed
        recent_attempt_quantity
        recent_attempt_percentage
        recent_attempt_history
        createdAt
        updatedAt
        userWordsId
      }
      nextToken
    }
  }
`;
export const getSpeakerWord = /* GraphQL */ `
  query GetSpeakerWord($sro: String!, $userID: String!) {
    getSpeakerWord(sro: $sro, userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_wordsId
    }
  }
`;
export const listSpeakerWords = /* GraphQL */ `
  query ListSpeakerWords(
    $sro: String
    $userID: ModelStringKeyConditionInput
    $filter: ModelSpeakerWordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSpeakerWords(
      sro: $sro
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        userSpeaker_wordsId
      }
      nextToken
    }
  }
`;
export const speakerWordByUserID = /* GraphQL */ `
  query SpeakerWordByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpeakerWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    speakerWordByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        userSpeaker_wordsId
      }
      nextToken
    }
  }
`;
export const getUserSyllable = /* GraphQL */ `
  query GetUserSyllable($sro: String!, $userID: String!) {
    getUserSyllable(sro: $sro, userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      user_confirmed
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;
export const listUserSyllables = /* GraphQL */ `
  query ListUserSyllables(
    $sro: String
    $userID: ModelStringKeyConditionInput
    $filter: ModelUserSyllableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSyllables(
      sro: $sro
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        user_confirmed
        createdAt
        updatedAt
        userSyllablesId
      }
      nextToken
    }
  }
`;
export const userSyllableByUserID = /* GraphQL */ `
  query UserSyllableByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSyllableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSyllableByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        user_confirmed
        createdAt
        updatedAt
        userSyllablesId
      }
      nextToken
    }
  }
`;
export const getSpeakerSyllable = /* GraphQL */ `
  query GetSpeakerSyllable($sro: String!, $userID: String!) {
    getSpeakerSyllable(sro: $sro, userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const listSpeakerSyllables = /* GraphQL */ `
  query ListSpeakerSyllables(
    $sro: String
    $userID: ModelStringKeyConditionInput
    $filter: ModelSpeakerSyllableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSpeakerSyllables(
      sro: $sro
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        userSpeaker_syllablesId
      }
      nextToken
    }
  }
`;
export const speakerSyllableByUserID = /* GraphQL */ `
  query SpeakerSyllableByUserID(
    $userID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpeakerSyllableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    speakerSyllableByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        sro
        userID
        bucketID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        audio {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        userSpeaker_syllablesId
      }
      nextToken
    }
  }
`;
export const getLevel = /* GraphQL */ `
  query GetLevel($level: Int!) {
    getLevel(level: $level) {
      level
      notes
      priority_words
      createdAt
      updatedAt
    }
  }
`;
export const listLevels = /* GraphQL */ `
  query ListLevels(
    $level: Int
    $filter: ModelLevelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLevels(
      level: $level
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        level
        notes
        priority_words
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserLevel = /* GraphQL */ `
  query GetUserLevel($userID: String!, $level: Int!) {
    getUserLevel(userID: $userID, level: $level) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      level
      intro_complete
      createdAt
      updatedAt
      userLevel_statusId
    }
  }
`;
export const listUserLevels = /* GraphQL */ `
  query ListUserLevels(
    $userID: String
    $level: ModelIntKeyConditionInput
    $filter: ModelUserLevelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserLevels(
      userID: $userID
      level: $level
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        level
        intro_complete
        createdAt
        updatedAt
        userLevel_statusId
      }
      nextToken
    }
  }
`;
