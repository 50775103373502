<template>
  <div>

    <va-modal v-model="showModal" :message="modalMessage"  />

    </div>
    

</template>

<script>



import { ref, computed } from 'vue';
import { useColors } from 'vuestic-ui';



export default {
  name: 'LeaderboardPage',
  components: {
  },
  props: {
  },

  setup(){


    const { getColors } = useColors()
    const colors = computed(getColors)

    const showModal = ref(false);



    return {
        colors,
        showModal

    }
  },

  async mounted(){

  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
#wordListTable {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

#wordListTable td {
  width: 250px;
  height: 20px !important;
  max-height: 20px !important;
  vertical-align: middle;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
