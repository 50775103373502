export default{
    wordXP: [
        0,10,25,45,70,100
    ],
    xp: [
        0,
        60,
        140,
        250,
        350,
        510,
        680,
        860,
        1040,
        1240,
        1450,
        1660,
        1900,
        2160,
        2460,
        2760,
        3060,
        3360,
        3660,
        3980,
        4340,
        4690,
        5050,
        5370,
        5760,
        6170,
        6550,
        6940,
        7280,
        7660,
        8070,
        8500,
        8880,
        9320,
        9720,
        10130,
        10540,
        11040,
        11460,
        11920,
        12370,
        12920,
        13440,
        13890,
        14440,
        14900,
        15540,
        16130,
        16680,
        17240,
        17800,
        18400,
        18950,
        19530,
        20050,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
        20600,
    ]
}