
import { deleteUser as mutation_deleteUser } from "../../graphql/mutations";
import { updateUser as mutation_updateUser } from "../../graphql/mutations";
import { getUser as query_getUser } from "../../graphql/myQueries";
import { onUpdateUser as subscription_onUpdateUser } from "../../graphql/mySubscriptions";



import { getTrainingInstance as query_getTrainingInstance } from "../../graphql/myQueries";

import { createUserWord as mutation_createUserWord } from "../../graphql/myMutations";
import { updateUserWord as mutation_updateUserWord } from "../../graphql/myMutations";

import { createSpeakerWord as mutation_createSpeakerWord } from "../../graphql/myMutations";
import { updateSpeakerWord as mutation_updateSpeakerWord } from "../../graphql/myMutations";

import { createSpeakerSyllable as mutation_createSpeakerSyllable } from "../../graphql/myMutations";
import { updateSpeakerSyllable as mutation_updateSpeakerSyllable } from "../../graphql/myMutations";

import { updateUserRequest as mutation_updateUserRequest } from "../../graphql/myMutations";

import { userWordByUserID as query_userWordByUserID } from "../../graphql/myQueries";
import { getUserWord as query_getUserWord } from "../../graphql/myQueries";

import { userSyllableByUserID as query_userSyllableByUserID } from "../../graphql/myQueries";
import { speakerWordByUserID as query_speakerWordByUserID } from "../../graphql/myQueries";
import { speakerSyllableByUserID as query_speakerSyllableByUserID } from "../../graphql/myQueries";
import { getUserWordPoint as query_getUserWordPoint } from "../../graphql/myQueries";
import { getUserRequest as query_getUserRequest } from "../../graphql/myQueries";

import { onCreateUserWord as subscription_onCreateUserWord } from "../../graphql/mySubscriptions";
import { onUpdateUserWord as subscription_onUpdateUserWord } from "../../graphql/mySubscriptions";
import { onCreateUserSyllable as subscription_onCreateUserSyllable } from "../../graphql/mySubscriptions";
import { onUpdateUserSyllable as subscription_onUpdateUserSyllable } from "../../graphql/mySubscriptions";
import { onUpdateUserWordPoint as subscription_onUpdateUserWordPoint } from "../../graphql/mySubscriptions";
import { onCreateUserLevel as subscription_onCreateUserLevel } from "../../graphql/mySubscriptions";
import { onUpdateUserLevel as subscription_onUpdateUserLevel } from "../../graphql/mySubscriptions";

import { onCreateTrainingInstance as subscription_onCreateTrainingInstance } from "../../graphql/mySubscriptions";
import { onUpdateTrainingInstance as subscription_onUpdateTrainingInstance } from "../../graphql/mySubscriptions";
import { onDeleteTrainingInstance as subscription_onDeleteTrainingInstance } from "../../graphql/mySubscriptions";

import { listLevels as query_listLevels } from "../../graphql/queries";

import { listUserLevels as query_listUserLevels } from "../../graphql/myQueries";
import { updateUserLevel as mutation_updateUserLevel } from "../../graphql/mutations";

import { onUpdateUserRequest as subscription_onUpdateUserRequest } from "../../graphql/mySubscriptions";

import { defineStore } from 'pinia'
import { API, graphqlOperation } from 'aws-amplify';

import { useWords } from '../Words/words'
import { useAuth } from '@/stores/Auth/auth'


export const useUsers = defineStore('users', {
  
  state: () => {
    return {
        userData: null,
        myWords: null,
        mySyllables: null,
        mySpeakerWords: null,
        mySpeakerSyllables: null,
        myWordPoints: null,
        myRequests: null,
        currentTrainingDefinition: null,
        calculatingXP: false,
        userLevelColour: "rgb(255, 217, 46)",
        subscriptions: {},
        myUserLevels: {},
        levels: {},
        requestHome: false,
    }
  },

  getters () {

    return {
        userData: (state)=>state.userData,
        myWords: (state)=>state.myWords,
        myUserLevels: (state)=>state.myUserLevels,
        mySyllables: (state)=>state.mySyllables,
        mySpeakerWords: (state)=>state.mySpeakerWords,
        mySpeakerSyllables: (state)=>state.mySpeakerSyllables,
        myWordPoints: (state)=>state.myWordPoints,
        calculatingXP: (state)=>state.calculatingXP,
        userLevelColour: (state)=>state.userLevelColour,
        myRequests: (state)=>state.myRequests,
        subscriptions: (state)=>state.subscriptions,
        currentTrainingDefinition: (state) => state.currentTrainingDefinition,
        levels: (state)=>state.levels,
        requestHome: (state)=>state.requestHome
    }        
  },

  actions: {


        async deleteUser(id)
        {
            try{


                await API.graphql(
                    graphqlOperation(mutation_deleteUser, { input: { id: id } })
                )

                return

            }catch(err)
            {
                console.error(err);

            }
        },  

        async updateUser(updates)
        {
            try{
                await API.graphql(
                    graphqlOperation(mutation_updateUser, { input: updates })
                )

                return

            }catch(err)
            {
                console.error(err);

            }
        },  

        async getUser(id)
        {
      
            try{
                const userQuery = await API.graphql(
                    graphqlOperation(query_getUser, { id: id })
                )

                this.userData = userQuery.data.getUser;    
            }catch(err)
            {
                console.error(err);
                
            }    

            let promises = [];

            promises.push((async()=>{

                try{
                    const trainingInstanceQuery = await API.graphql(
                        graphqlOperation(query_getTrainingInstance, { userID: id })
                    )
                    if(trainingInstanceQuery.data.getTrainingInstance && trainingInstanceQuery.data.getTrainingInstance.component_results)
                    {
                        trainingInstanceQuery.data.getTrainingInstance.component_results = JSON.parse(trainingInstanceQuery.data.getTrainingInstance.component_results);
                    }
                    
                    this.userData.trainingInstance = trainingInstanceQuery.data.getTrainingInstance;
                    if(this.userData.trainingInstance && this.userData.trainingInstance.definition)
                    {
                        this.currentTrainingDefinition = this.userData.trainingInstance.definition;
                    }
                    
                    
                }catch(err)
                {
                    console.error(err);
                }

            })());

            promises.push((async()=>{
                
                try{
                    const getUserRequestQuery = await API.graphql(
                        graphqlOperation(query_getUserRequest, { userID: id })
                    )
                    this.myRequests = getUserRequestQuery.data.getUserRequest;
                    
                }catch(err)
                {
                    console.error(err);
                }

            })());

            promises.push((async()=>{
                
                try{
                    const listUserLevelsQuery = await API.graphql(
                        graphqlOperation(query_listUserLevels, { userID: id })
                    )

                    for(let item of listUserLevelsQuery.data.listUserLevels.items)
                    {
                        this.myUserLevels[item.level] = JSON.parse(JSON.stringify(item));
                    }

                    
                }catch(err)
                {
                    console.error(err);
                }

            })());

            await Promise.all(promises);
 
        },  

        async updateUserLevel(updates)
        {
            try{
                await API.graphql(
                    graphqlOperation(mutation_updateUserLevel, { input: updates })
                )

                return

            }catch(err)
            {
                console.error(err);
            }
        },  

        
        async updateUserRequest(updates)
        {

 
            try{
                await API.graphql(
                    graphqlOperation(mutation_updateUserRequest, { input: updates })
                )


                return

            }catch(err)
            {
                console.error(err);
            }
        },  


        async getUserWord(queryParams)
        {
            try{

                const wordStore = useWords()

                let userWordQuery = await API.graphql(
                    graphqlOperation(query_getUserWord, { sro: queryParams.sro, userID: queryParams.userID })
                )

                let word = userWordQuery.data.getUserWord;

                
                if(!Object.prototype.hasOwnProperty.call(wordStore.words, word.sro))
                {
                    await wordStore.getWord(word.sro);                   
                }

                this.myWords[word.sro] = word;

                return

            }catch(err)
            {
                console.error(err);

            }
        },  

        
        async getUserWords(userID)
        {
            try{

                const wordStore = useWords()

                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {
                    userID: userID
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_userWordByUserID, queryParams)
                    )
                
                    let words = page.data.userWordByUserID.items;
                    nextToken = page.data.userWordByUserID.nextToken;
                    
                    let promises = [];
                    for(let record of words)
                    {
                        allRecords[record.sro] = record;

                        if(record.user_confirmed != true)
                        {
                            await this.updateUserRequest({
                                    confirm_word: record.sro,
                                    userID: userID,
                            })
                        }

                        if(!Object.prototype.hasOwnProperty.call(wordStore.words, record.sro))
                        {
                            promises.push((async()=>{
                                await wordStore.getWord(record.sro);
                            })());
                            
                        }
                    }

                    await Promise.all(promises);
                    
                    pages++;
                }

                this.myWords = allRecords;

                
            } catch(error){
                console.error(error);
            }
        },

        async getUserSyllables(userID)
        {
            try{

                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {
                    userID: userID
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_userSyllableByUserID, queryParams)
                    )
                
                    let words = page.data.userSyllableByUserID.items;
                    nextToken = page.data.userSyllableByUserID.nextToken;
                    
                    for(let record of words)
                    {
                        allRecords[record.sro] = record;
                    }
                    
                    pages++;
                }

                this.mySyllables = allRecords;
                

                
            } catch(error){
                console.error(error);
            }
        },

        async getSpeakerWords(userID)
        {
            try{

                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {
                    userID: userID
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_speakerWordByUserID, queryParams)
                    )
                
                    let words = page.data.speakerWordByUserID.items;
                    nextToken = page.data.speakerWordByUserID.nextToken;
                    
                    for(let record of words)
                    {
                        allRecords[record.sro] = record;
                    }
                    
                    pages++;
                }

                this.mySpeakerWords = allRecords;
                

                
            } catch(error){
                console.error(error);
            }
        },

        async getSpeakerSyllables(userID)
        {
            try{

                let allRecords = {};
                let pages = 0;
                let nextToken = null;

                let queryParams = {
                    userID: userID
                }

                while(nextToken != null || pages == 0)
                {
                    
                    if(nextToken != null) queryParams.nextToken = nextToken;

                    let page = await API.graphql(
                        graphqlOperation(query_speakerSyllableByUserID, queryParams)
                    )
                
                    let words = page.data.speakerSyllableByUserID.items;
                    nextToken = page.data.speakerSyllableByUserID.nextToken;
                    
                    for(let record of words)
                    {
                        allRecords[record.sro] = record;
                    }
                    
                    pages++;
                }

                this.mySpeakerSyllables = allRecords;
                            
            } catch(error){
                console.error(error);
            }
        },

        
        async getUserWordPoints(userID)
        {
            try{
                const query = await API.graphql(
                    graphqlOperation(query_getUserWordPoint, { userID: userID })
                )

                this.myWordPoints = query.data.getUserWordPoint;

            }catch(err)
            {
                console.error(err);

            }
        },  

        async getUserRequests(userID)
        {
            try{
                const query = await API.graphql(
                    graphqlOperation(query_getUserRequest, { userID: userID })
                )

                this.myRequests = query.data.getUserRequest;

            }catch(err)
            {
                console.error(err);

            }
        },  


        
        async createSpeakerWord(createObject)
        {
            try{

                let newWord = await API.graphql(
                    graphqlOperation(mutation_createSpeakerWord, { input: createObject })
                )

                this.mySpeakerWords[newWord.data.createSpeakerWord.sro] = newWord.data.createSpeakerWord;

                return

            }catch(err)
            {
                console.error(err);

            }
        },

             
        async updateSpeakerWord(updateObject)
        {
            try{

                let update = await API.graphql(
                    graphqlOperation(mutation_updateSpeakerWord, { input: updateObject })
                )

                this.mySpeakerWords[update.data.updateSpeakerWord.sro] = update.data.updateSpeakerWord;

                return

            }catch(err)
            {
                console.error(err);

            }
        },

             
        async createSpeakerSyllable(createObject)
        {
            try{

                let newSyllable = await API.graphql(
                    graphqlOperation(mutation_createSpeakerSyllable, { input: createObject })
                )

                this.mySpeakerSyllables[newSyllable.data.createSpeakerSyllable.sro] = newSyllable.data.createSpeakerSyllable;

                return

            }catch(err)
            {
                console.error(err);

            }
        },

             
        async updateSpeakerSyllable(updateObject)
        {
            try{

                let update = await API.graphql(
                    graphqlOperation(mutation_updateSpeakerSyllable, { input: updateObject })
                )

                this.mySpeakerSyllables[update.data.updateSpeakerSyllable.sro] = update.data.updateSpeakerSyllable;

                return

            }catch(err)
            {
                console.error(err);

            }
        },


        async createUserWord(createObject)
        {
            try{

                let newWord = await API.graphql(
                    graphqlOperation(mutation_createUserWord, { input: createObject })
                )

                this.myWords[newWord.data.createUserWord.sro] = newWord.data.mutation_createUserWord;

                return

            }catch(err)
            {
                console.error(err);

            }
        },

        async updateUserWord(updateObject)
        {
            try{

                let update = await API.graphql(
                    graphqlOperation(mutation_updateUserWord, { input: updateObject })
                )

                this.myWords[update.data.updateUserWord.sro] = update.data.updateUserWord;

                return

            }catch(err)
            {
                console.error(err);

            }
        },

        async getLevels()
        {


            try{
                const query = await API.graphql(
                    graphqlOperation(query_listLevels)
                )

                for(let level of query.data.listLevels.items)
                {
                    this.levels[level.level] = JSON.parse(JSON.stringify(level));
                }
    
            }catch(err)
            {
                console.error(err);

            }

        },

        async setupSubscriptions(explicit)
        {
            const authStore = useAuth();

            if(explicit == null || explicit == 'onUpdateUserRequest')
            {
                if(this.subscriptions['onUpdateUserRequest']) 
                {
                    await this.subscriptions['onUpdateUserRequest'].unsubscribe();
                    this.subscriptions['onUpdateUserRequest'] = null
                }

                this.subscriptions['onUpdateUserRequest'] = API.graphql(
                    graphqlOperation(subscription_onUpdateUserRequest,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateUserRequest;        
    
                        this.myRequests = data;
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateUserRequest');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onCreateUserWord')
            {
                if(this.subscriptions['onCreateUserWord']) 
                {
                    await this.subscriptions['onCreateUserWord'].unsubscribe();
                    this.subscriptions['onCreateUserWord'] = null
                }
                this.subscriptions['onCreateUserWord'] = API.graphql(
                    graphqlOperation(subscription_onCreateUserWord,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: async ({ value }) => {

                        console.log("New word!");
                        console.log(value.data.onCreateUserWord);
                        const wordStore = useWords();
                        let data = value.data.onCreateUserWord;        
                        await wordStore.getWord(data.sro);
                        this.myWords[data.sro] = data;
                        this.myWords[data.sro].isNew = true;    

                        await this.updateUserRequest({
                            confirm_word: data.sro,
                            userID: data.userID,
                        })

                        
                        
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onCreateUserWord');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onUpdateUserWord')
            {
                if(this.subscriptions['onUpdateUserWord']) 
                {
                    await this.subscriptions['onUpdateUserWord'].unsubscribe();
                    this.subscriptions['onUpdateUserWord'] = null
                }
                this.subscriptions['onUpdateUserWord'] = API.graphql(
                    graphqlOperation(subscription_onUpdateUserWord,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                    
                        let data = value.data.onUpdateUserWord;

                        for(let key in data)
                        {
                            if(data[key] == null) continue
                            if(this.myWords[data.sro][key] != data[key]) this.myWords[data.sro][key] = data[key];
                        }    
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateUserWord');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onCreateUserLevel')
            {
                if(this.subscriptions['onCreateUserLevel']) 
                {
                    await this.subscriptions['onCreateUserLevel'].unsubscribe();
                    this.subscriptions['onCreateUserLevel'] = null
                }
                this.subscriptions['onCreateUserLevel'] = API.graphql(
                    graphqlOperation(subscription_onCreateUserLevel,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: async ({ value }) => {

                            
                            let data = value.data.onCreateUserLevel;
                            this.myUserLevels[data.level] = JSON.parse(JSON.stringify(data));        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    
                            this.setupSubscriptions('onCreateUserLevel');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onUpdateUserLevel')
            {
                if(this.subscriptions['onUpdateUserLevel']) 
                {
                    await this.subscriptions['onUpdateUserLevel'].unsubscribe();
                    this.subscriptions['onUpdateUserLevel'] = null
                }
                this.subscriptions['onUpdateUserLevel'] = API.graphql(
                    graphqlOperation(subscription_onUpdateUserLevel,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                    
                            let data = value.data.onUpdateUserLevel;

                            for(let key in data)
                            {
                                if(data[key] == null) continue
                                if(this.myUserLevels[data.level][key] != data[key]) this.myUserLevels[data.level][key] = data[key];
                            }    
                            
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateUserLevel');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onCreateUserSyllable')
            {
                if(this.subscriptions['onCreateUserSyllable']) 
                {
                    await this.subscriptions['onCreateUserSyllable'].unsubscribe();
                    this.subscriptions['onCreateUserSyllable'] = null
                }
                this.subscriptions['onCreateUserSyllable'] = API.graphql(
                    graphqlOperation(subscription_onCreateUserSyllable,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onCreateUserSyllable;        
                        this.mySyllables[data.sro] = data;
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onCreateUserSyllable');
    
                        },1000)
                    }
                });

            }

            
            if(explicit == null || explicit == 'onUpdateUserSyllable')
            {
                if(this.subscriptions['onUpdateUserSyllable']) 
                {
                    await this.subscriptions['onUpdateUserSyllable'].unsubscribe();
                    this.subscriptions['onUpdateUserSyllable'] = null
                }
                this.subscriptions['onUpdateUserSyllable'] = API.graphql(
                    graphqlOperation(subscription_onUpdateUserSyllable,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateUserSyllable;
                        for(let key in data)
                        {
                            if(this.mySyllables[data.sro][key] != data[key]) this.mySyllables[data.sro][key] = data[key];
                        }    
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateUserSyllable');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onUpdateUserWordPoint')
            {
                if(this.subscriptions['onUpdateUserWordPoint']) 
                {
                    await this.subscriptions['onUpdateUserWordPoint'].unsubscribe();
                    this.subscriptions['onUpdateUserWordPoint'] = null
                }

                this.subscriptions['onUpdateUserWordPoint'] = API.graphql(
                    graphqlOperation(subscription_onUpdateUserWordPoint,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateUserWordPoint;
                        for(let key in data)
                        {
                            if(this.myWordPoints[key] != data[key]) this.myWordPoints[key] = data[key];
                        }    
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateUserWordPoint');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onUpdateUser')
            {
                if(this.subscriptions['onUpdateUser']) 
                {
                    await this.subscriptions['onUpdateUser'].unsubscribe();
                    this.subscriptions['onUpdateUser'] = null
                }

                this.subscriptions['onUpdateUser'] = API.graphql(
                    graphqlOperation(subscription_onUpdateUser,  { id: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateUser;
                        for(let key in data)
                        {
                            if(this.userData[key] != data[key]) this.userData[key] = data[key];
                        }    
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateUser');
    
                        },1000)
                    }
                });
            }

            
            if(explicit == null || explicit == 'onCreateTrainingInstance')
            {
                if(this.subscriptions['onCreateTrainingInstance']) 
                {
                    await this.subscriptions['onCreateTrainingInstance'].unsubscribe();
                    this.subscriptions['onCreateTrainingInstance'] = null
                }

                this.subscriptions['onCreateTrainingInstance'] = API.graphql(
                    graphqlOperation(subscription_onCreateTrainingInstance,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onCreateTrainingInstance;
                        data.component_results = JSON.parse(data.component_results);
                        this.userData.trainingInstance = data;
              
                            if(this.userData.trainingInstance.definition)
                            {
                                this.currentTrainingDefinition = this.userData.trainingInstance.definition;
                            }
                        }
                        
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onCreateTrainingInstance');
    
                        },1000)
                    }
                });
            }

            if(explicit == null || explicit == 'onUpdateTrainingInstance')
            {
                if(this.subscriptions['onUpdateTrainingInstance']) 
                {
                    await this.subscriptions['onUpdateTrainingInstance'].unsubscribe();
                    this.subscriptions['onUpdateTrainingInstance'] = null
                }
                this.subscriptions['onUpdateTrainingInstance'] = API.graphql(
                    graphqlOperation(subscription_onUpdateTrainingInstance,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: ({ value }) => {
                        
                        let data = value.data.onUpdateTrainingInstance;
                        data.component_results = JSON.parse(data.component_results);
                        this.userData.trainingInstance = data;
                                
                            if(this.userData.trainingInstance.definition)
                            {
                                this.currentTrainingDefinition = this.userData.trainingInstance.definition;
                            }
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onUpdateTrainingInstance');
    
                        },1000)
                    }
                });
            }


            if(explicit == null || explicit == 'onDeleteTrainingInstance')
            {
                if(this.subscriptions['onDeleteTrainingInstance']) 
                {
                    await this.subscriptions['onDeleteTrainingInstance'].unsubscribe();
                    this.subscriptions['onDeleteTrainingInstance'] = null
                }
                this.subscriptions['onDeleteTrainingInstance'] = API.graphql(
                    graphqlOperation(subscription_onDeleteTrainingInstance,  { userID: authStore.userAuthDetails.attributes.sub } )
                ).subscribe({
                    next: () => {
                        
                        this.userData.trainingInstance = null;
                        
                        }
                    ,
                    error: error => {
                        console.warn(error)
                        if(error.errors)
                        setTimeout(()=>{
    

                            this.setupSubscriptions('onDeleteTrainingInstance');
    
                        },1000)
                    }
                });
            }
            

           
        }




    }

})