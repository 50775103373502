import { Auth } from "aws-amplify";

import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useAuth = defineStore('auth', {
  // other options...
  state: () => {
    return {
        user: null, userGroups: null, userAuthDetails: null, currentCredentials: null, fullyLoaded: false,
        sessionTimer: 0,
    }
  },

  getters () {

    return {
        user: (state)=>state.user,
        userGroups: (state)=>state.userGroups,
        userAuthDetails: (state)=>state.userAuthDetails,
        currentCredentials: (state) => state.currentCredentials,
        fullyLoaded: (state) => state.fullyLoaded,
        sessionTimer: (state) => state.sessionTimer,
    }        
  },

  actions: {

    async logout() {
        this.user = null;
        return await Auth.signOut();
    },

    async login(username, password) {

      try {
          await Auth.signIn({
              username, 
              password
          });

          const userInfo = await Auth.currentUserInfo();
          this.user = userInfo;
          
          let userDetails = await Auth.currentAuthenticatedUser();

          
          this.userGroups = userDetails.signInUserSession.accessToken.payload['cognito:groups'];

       
                
          return Promise.resolve(userDetails);

        } catch(error){
            console.log(error);
            return Promise.reject(error);
        }

      },

          
      async confirmSignUp(username, code) {

          try{
              await Auth.confirmSignUp(username, code);
              return Promise.resolve();

          } catch(error){
              console.log(error);
              return Promise.reject(error);
          }
      },

      async signUp(_, {username, password, email}){
          try{
              let userDetails = await Auth.signUp({
                  username,
                  password,
                  attributes: {
                      email
                  }
              })
              return userDetails;

          } catch(error){
              console.log(error);
              return Promise.reject(error);
          }
        },

            
        async authAction() {

            try{
                const userInfo = await Auth.currentUserInfo();
                this.user = userInfo;
                let userDetails = await Auth.currentAuthenticatedUser();
                
                this.userGroups = userDetails.signInUserSession.accessToken.payload['cognito:groups'];
                this.userAuthDetails = userDetails;
    
                const currentCredentials = await Auth.currentCredentials();
                this.currentCredentials = currentCredentials;
    
            }catch(err){
                console.log(err);
                this.user = null;
                await Auth.signOut();
                return 
            }

            

        },

  }

})

