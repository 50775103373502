/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      id
      user_name
      name
      location
      speaker
      requesting_speaker
      words {
        items {
          sro
          userID
          bucketID
          level
          xp
          user_confirmed
          recent_attempt_quantity
          recent_attempt_percentage
          recent_attempt_history
          createdAt
          updatedAt
          userWordsId
        }
        nextToken
      }
      speaker_words {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_wordsId
        }
        nextToken
      }
      syllables {
        items {
          sro
          userID
          bucketID
          user_confirmed
          createdAt
          updatedAt
          userSyllablesId
        }
        nextToken
      }
      speaker_syllables {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_syllablesId
        }
        nextToken
      }
      level
      xp
      wordPoints {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        points
        createdAt
        updatedAt
        userWordPointUserId
      }
      requests {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        unlock_words
        unlock_phrase
        confirm_word
        set_default_speaker
        start_training
        submit_answer
        answer_string
        next_session
        end_session
        change_name
        change_location
        request_speaker_access
        request_hint
        request_hint_working_answer
        createdAt
        updatedAt
        userRequestUserId
      }
      level_status {
        items {
          userID
          level
          intro_complete
          createdAt
          updatedAt
          userLevel_statusId
        }
        nextToken
      }
      defaultSpeakerID
      trainingInstance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      most_recent_word
      createdAt
      updatedAt
      userWordPointsId
      userRequestsId
      userTrainingInstanceId
    }
  }
`;
export const onCreateUserWord = /* GraphQL */ `
  subscription OnCreateUserWord($userID: String!) {
    onCreateUserWord(userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      level
      xp
      user_confirmed
      recent_attempt_quantity
      recent_attempt_percentage
      recent_attempt_history
      createdAt
      updatedAt
      userWordsId
    }
  }
`;
export const onUpdateUserWord = /* GraphQL */ `
  subscription OnUpdateUserWord($userID: String!) {
    onUpdateUserWord(userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      level
      xp
      user_confirmed
      recent_attempt_quantity
      recent_attempt_percentage
      recent_attempt_history
      createdAt
      updatedAt
      userWordsId
    }
  }
`;
export const onCreateUserSyllable = /* GraphQL */ `
  subscription OnCreateUserSyllable($userID: String!) {
    onCreateUserSyllable(userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      user_confirmed
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;
export const onUpdateUserSyllable = /* GraphQL */ `
  subscription OnUpdateUserSyllable($userID: String!) {
    onUpdateUserSyllable(userID: $userID) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      user_confirmed
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;
export const onUpdateUserWordPoint = /* GraphQL */ `
  subscription OnUpdateUserWordPoint($userID: String!) {
    onUpdateUserWordPoint(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      points
      createdAt
      updatedAt
      userWordPointUserId
    }
  }
`;
export const onUpdateUserRequest = /* GraphQL */ `
  subscription OnUpdateUserRequest($userID: String!) {
    onUpdateUserRequest(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      unlock_words
      unlock_phrase
      confirm_word
      set_default_speaker
      start_training
      submit_answer
      answer_string
      next_session
      end_session
      change_name
      change_location
      request_speaker_access
      request_hint
      request_hint_working_answer
      createdAt
      updatedAt
      userRequestUserId
    }
  }
`;
export const onCreateTrainingInstance = /* GraphQL */ `
  subscription OnCreateTrainingInstance($userID: String!) {
    onCreateTrainingInstance(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      definition
      num_syllables
      num_letters
      word_level
      answer {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      component_results
      clue_audio_bucketID
      clue_audio_key
      clue_audio_user_id
      space_locations
      hints
      type
      createdAt
      updatedAt
      trainingInstanceAnswerId
      trainingInstanceUserId
    }
  }
`;
export const onUpdateTrainingInstance = /* GraphQL */ `
  subscription OnUpdateTrainingInstance($userID: String!) {
    onUpdateTrainingInstance(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      definition
      num_syllables
      num_letters
      word_level
      answer {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      component_results
      clue_audio_bucketID
      clue_audio_key
      clue_audio_user_id
      space_locations
      hints
      type
      createdAt
      updatedAt
      trainingInstanceAnswerId
      trainingInstanceUserId
    }
  }
`;
export const onDeleteTrainingInstance = /* GraphQL */ `
  subscription OnDeleteTrainingInstance($userID: String!) {
    onDeleteTrainingInstance(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      definition
      num_syllables
      num_letters
      word_level
      answer {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      component_results
      clue_audio_bucketID
      clue_audio_key
      clue_audio_user_id
      space_locations
      hints
      type
      createdAt
      updatedAt
      trainingInstanceAnswerId
      trainingInstanceUserId
    }
  }
`;
export const onCreateUserLevel = /* GraphQL */ `
  subscription OnCreateUserLevel($userID: String!) {
    onCreateUserLevel(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      level
      intro_complete
      createdAt
      updatedAt
      userLevel_statusId
    }
  }
`;
export const onUpdateUserLevel = /* GraphQL */ `
  subscription OnUpdateUserLevel($userID: String!) {
    onUpdateUserLevel(userID: $userID) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      level
      intro_complete
      createdAt
      updatedAt
      userLevel_statusId
    }
  }
`;
export const onCreateSyllable = /* GraphQL */ `
  subscription OnCreateSyllable($filter: ModelSubscriptionSyllableFilterInput) {
    onCreateSyllable(filter: $filter) {
      sro
      syllable
      CwV
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSyllable = /* GraphQL */ `
  subscription OnUpdateSyllable($filter: ModelSubscriptionSyllableFilterInput) {
    onUpdateSyllable(filter: $filter) {
      sro
      syllable
      CwV
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSyllable = /* GraphQL */ `
  subscription OnDeleteSyllable($filter: ModelSubscriptionSyllableFilterInput) {
    onDeleteSyllable(filter: $filter) {
      sro
      syllable
      CwV
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWord = /* GraphQL */ `
  subscription OnCreateWord($filter: ModelSubscriptionWordFilterInput) {
    onCreateWord(filter: $filter) {
      sro
      definition
      type
      syllabics
      num_syllables
      morpheme {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      level
      dependencies
      notes
      image_url
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const onUpdateWord = /* GraphQL */ `
  subscription OnUpdateWord($filter: ModelSubscriptionWordFilterInput) {
    onUpdateWord(filter: $filter) {
      sro
      definition
      type
      syllabics
      num_syllables
      morpheme {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      level
      dependencies
      notes
      image_url
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const onDeleteWord = /* GraphQL */ `
  subscription OnDeleteWord($filter: ModelSubscriptionWordFilterInput) {
    onDeleteWord(filter: $filter) {
      sro
      definition
      type
      syllabics
      num_syllables
      morpheme {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      level
      dependencies
      notes
      image_url
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const onCreateWordCount = /* GraphQL */ `
  subscription OnCreateWordCount(
    $filter: ModelSubscriptionWordCountFilterInput
  ) {
    onCreateWordCount(filter: $filter) {
      level
      count
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWordCount = /* GraphQL */ `
  subscription OnUpdateWordCount(
    $filter: ModelSubscriptionWordCountFilterInput
  ) {
    onUpdateWordCount(filter: $filter) {
      level
      count
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWordCount = /* GraphQL */ `
  subscription OnDeleteWordCount(
    $filter: ModelSubscriptionWordCountFilterInput
  ) {
    onDeleteWordCount(filter: $filter) {
      level
      count
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMorpheme = /* GraphQL */ `
  subscription OnCreateMorpheme($filter: ModelSubscriptionMorphemeFilterInput) {
    onCreateMorpheme(filter: $filter) {
      sro
      definition
      syllabics
      level
      active
      words {
        items {
          sro
          definition
          type
          syllabics
          num_syllables
          level
          dependencies
          notes
          image_url
          createdAt
          updatedAt
          morphemeWordsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMorpheme = /* GraphQL */ `
  subscription OnUpdateMorpheme($filter: ModelSubscriptionMorphemeFilterInput) {
    onUpdateMorpheme(filter: $filter) {
      sro
      definition
      syllabics
      level
      active
      words {
        items {
          sro
          definition
          type
          syllabics
          num_syllables
          level
          dependencies
          notes
          image_url
          createdAt
          updatedAt
          morphemeWordsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMorpheme = /* GraphQL */ `
  subscription OnDeleteMorpheme($filter: ModelSubscriptionMorphemeFilterInput) {
    onDeleteMorpheme(filter: $filter) {
      sro
      definition
      syllabics
      level
      active
      words {
        items {
          sro
          definition
          type
          syllabics
          num_syllables
          level
          dependencies
          notes
          image_url
          createdAt
          updatedAt
          morphemeWordsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrainingInstanceAnswer = /* GraphQL */ `
  subscription OnCreateTrainingInstanceAnswer(
    $filter: ModelSubscriptionTrainingInstanceAnswerFilterInput
  ) {
    onCreateTrainingInstanceAnswer(filter: $filter) {
      instanceID
      answer
      instance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      createdAt
      updatedAt
      trainingInstanceAnswerInstanceId
    }
  }
`;
export const onUpdateTrainingInstanceAnswer = /* GraphQL */ `
  subscription OnUpdateTrainingInstanceAnswer(
    $filter: ModelSubscriptionTrainingInstanceAnswerFilterInput
  ) {
    onUpdateTrainingInstanceAnswer(filter: $filter) {
      instanceID
      answer
      instance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      createdAt
      updatedAt
      trainingInstanceAnswerInstanceId
    }
  }
`;
export const onDeleteTrainingInstanceAnswer = /* GraphQL */ `
  subscription OnDeleteTrainingInstanceAnswer(
    $filter: ModelSubscriptionTrainingInstanceAnswerFilterInput
  ) {
    onDeleteTrainingInstanceAnswer(filter: $filter) {
      instanceID
      answer
      instance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      createdAt
      updatedAt
      trainingInstanceAnswerInstanceId
    }
  }
`;
export const onCreateSpeakerWord = /* GraphQL */ `
  subscription OnCreateSpeakerWord(
    $filter: ModelSubscriptionSpeakerWordFilterInput
  ) {
    onCreateSpeakerWord(filter: $filter) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_wordsId
    }
  }
`;
export const onUpdateSpeakerWord = /* GraphQL */ `
  subscription OnUpdateSpeakerWord(
    $filter: ModelSubscriptionSpeakerWordFilterInput
  ) {
    onUpdateSpeakerWord(filter: $filter) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_wordsId
    }
  }
`;
export const onDeleteSpeakerWord = /* GraphQL */ `
  subscription OnDeleteSpeakerWord(
    $filter: ModelSubscriptionSpeakerWordFilterInput
  ) {
    onDeleteSpeakerWord(filter: $filter) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_wordsId
    }
  }
`;
export const onCreateSpeakerSyllable = /* GraphQL */ `
  subscription OnCreateSpeakerSyllable(
    $filter: ModelSubscriptionSpeakerSyllableFilterInput
  ) {
    onCreateSpeakerSyllable(filter: $filter) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const onUpdateSpeakerSyllable = /* GraphQL */ `
  subscription OnUpdateSpeakerSyllable(
    $filter: ModelSubscriptionSpeakerSyllableFilterInput
  ) {
    onUpdateSpeakerSyllable(filter: $filter) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const onDeleteSpeakerSyllable = /* GraphQL */ `
  subscription OnDeleteSpeakerSyllable(
    $filter: ModelSubscriptionSpeakerSyllableFilterInput
  ) {
    onDeleteSpeakerSyllable(filter: $filter) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const onCreateLevel = /* GraphQL */ `
  subscription OnCreateLevel($filter: ModelSubscriptionLevelFilterInput) {
    onCreateLevel(filter: $filter) {
      level
      notes
      priority_words
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLevel = /* GraphQL */ `
  subscription OnUpdateLevel($filter: ModelSubscriptionLevelFilterInput) {
    onUpdateLevel(filter: $filter) {
      level
      notes
      priority_words
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLevel = /* GraphQL */ `
  subscription OnDeleteLevel($filter: ModelSubscriptionLevelFilterInput) {
    onDeleteLevel(filter: $filter) {
      level
      notes
      priority_words
      createdAt
      updatedAt
    }
  }
`;
