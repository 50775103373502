<template>
  <div>

    <va-modal v-model="showModal" :message="modalMessage"  />

     <div style="display: flex; justify-content: center; flex-wrap: nowrap; margin-top: 25px; margin-bottom: 25px">

     <va-button-group class="mb-4" size="medium" style="width: 90%; margin-top: 25px">
      <va-button style="width: 33.3%;" @click="section = 'Needed Words'" :color="section == 'Needed Words' ? 'primary' : '#000'" :style="{color: section == 'Needed Words' ? '#000' : '#FFF'}">Needed Words</va-button>
      <va-button style="width: 33.3%;" @click="section = 'All Words'" :color="section == 'All Words' ? 'primary' : '#000'" :style="{color: section == 'All Words' ? '#000' : '#FFF'}">All Words</va-button>
      <va-button style="width: 33.3%;" @click="section = 'Syllables'" :color="section == 'Syllables' ? 'primary' : '#000'" :style="{color: section == 'Syllables' ? '#000' : '#FFF'}">Syllables</va-button>
    </va-button-group>

    </div>
  
    <template v-if="loading == false">

      <template v-if="section == 'Needed Words'">

        <table class="va-table" id="wordListTable" style="font-size: 24px; width: 85%; margin-left: 7.5%" >

          <tr>
            <th colspan='2' style="text-align: center; font-size: 20px">Most Common Words That Still Need Your Audio</th>
          </tr>

          <tr>
            <th colspan='2' style="text-align: center; font-size: 20px"><va-button @click="getTopWordList()">REFRESH</va-button></th>
          </tr>

          <template v-for="(word, w) in topWordList" :key="'wordList' + w">
          <tr>
          <td  style="text-align: left; font-weight: bold; max-width: 130px; padding-right: 15px; padding-top: 20px; vertical-align: top;">
          <va-divider />
            #{{ w + 1 }}: {{ word.sro }}
          </td>

          </tr>
          <tr>
          <td>
            
            <table class="va-table" style="width:100%">
              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">English</td>
              </tr>
              <tr>
                <td style="padding-top: 0px; height: 20px; width: 350px;"> {{ word.definition }}</td>
              </tr>

              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">Syllabics</td>
              </tr>
              <tr>
                <td style="padding-top: 0px; width: 350px;"> {{ word.syllabics }}</td>
              </tr>

              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">My Audio</td>
              </tr>
              <tr>
                <td style="padding-top: 5px; width: 350px;"> 
                  <va-button
                    icon="volume_up"
                    size="large"
                    :rounded="false"
                    style="width: 200px; height: 50px;" v-if="userStore.mySpeakerWords[word.sro]" @click="playAudioFile(userStore.mySpeakerWords[word.sro])">
                  </va-button>
                  <div v-else style="color: red">Not Yet Recorded</div>
                </td>
              </tr>

              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">Record Audio</td>
              </tr>
              <tr>
                  <td style="padding-top: 5px; width: 350px;"> 
                  <va-button
                      :rounded="false"
                      color="#000"
                      outline
                      size="large"
                      icon="mic"
                      v-if="recordingAudio == false"
                      style="width: 200px; height: 50px"  @click="recordAudioFile(word.sro,'word')">
                  </va-button>

                  <va-button
                      v-else-if="activeAudioWord == word.sro"
                      :rounded="false"
                      color="danger"
                      size="large"
                      icon="stop"
                      style="width: 200px; height: 50px" @click="stopAudioRecording()">
                  </va-button>
                </td>
              </tr>


            </table>
          </td>

          </tr>
          </template>

          </table>

      </template>

      <template v-if="section == 'All Words'">
      
        <div style="display: flex; justify-content: flex-start; flex-wrap: wrap; width: 90%; margin-left: 5%; margin-right: 5%; margin-top: 15px; margin-bottom: 10px">
            <div style="width: 48px; min-width: 48px; height: 36px; margin-left: 1.25%; margin-right: 1.25%; margin-bottom: 10px;" v-for="(letter,l) in morphemeLetterOptions" :key="'letterFilterCell' +l">


              <va-button 
                v-on:click="filterWordListLetter = letter"
                :outline="filterWordListLetter == letter ? false : true"
                size="small"
                :rounded="false"      
                color="#000"        
                style="width: 100%; height: 36px; font-size: 18px;"
                :key="'filterButton' + l"
              >          
                {{ letter }}
                
              </va-button>

            </div>
        </div>

        <va-select
          style="height: 35px; font-size: 36px; margin-top: 15px; margin-left: 5%; margin-right: 5%;"
          color="#000"
          v-model="selectedMorpheme"
          :options="morphemeOptions_filtered"
          placement="top"
          label="Word Group"
        ></va-select>


      <table class="va-table" id="wordListTable" style="font-size: 24px; width: 85%; margin-left: 7.5%" v-if="sortedWordList.length > 0">


          <template v-for="(word, w) in sortedWordList" :key="'wordList' + w">
          <tr>
          <td  style="text-align: left; font-weight: bold; max-width: 130px; padding-right: 15px; padding-top: 20px; vertical-align: top;">
          <va-divider />
            {{ word.sro }}
          </td>

          </tr>
          <tr>
          <td>
            
             <table class="va-table" style="width:100%">
              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">English</td>
              </tr>
              <tr>
                <td style="padding-top: 0px; height: 20px; width: 350px;"> {{ word.definition }}</td>
              </tr>

              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">Syllabics</td>
              </tr>
              <tr>
                <td style="padding-top: 0px; width: 350px;"> {{ word.syllabics }}</td>
              </tr>
           
              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">My Audio</td>
              </tr>
              <tr>
                 <td style="padding-top: 5px; width: 350px;"> 
                  <va-button
                    icon="volume_up"
                    size="large"
                    :rounded="false"
                    style="width: 200px; height: 50px;" v-if="userStore.mySpeakerWords[word.sro]" @click="playAudioFile(userStore.mySpeakerWords[word.sro])">
                  </va-button>
                  <div v-else style="color: red">Not Yet Recorded</div>
                </td>
              </tr>

              <tr>
                <td style="padding-bottom: 5px; height: 20px; font-size: 12px; width: 350px;">Record Audio</td>
              </tr>
              <tr>
                  <td style="padding-top: 5px; width: 350px;"> 
                  <va-button
                      :rounded="false"
                      color="#000"
                      outline
                      size="large"
                      icon="mic"
                      v-if="recordingAudio == false"
                      style="width: 200px; height: 50px"  @click="recordAudioFile(word.sro,'word')">
                  </va-button>

                  <va-button
                      v-else-if="activeAudioWord == word.sro"
                      :rounded="false"
                      color="danger"
                      size="large"
                      icon="stop"
                      style="width: 200px; height: 50px" @click="stopAudioRecording()">
                  </va-button>
                </td>
              </tr>


            </table>
          </td>

          </tr>
          </template>

        </table>

      </template>

      <template v-else-if="section == 'Syllables'">
      
      <table class="va-table" id="wordListTable" style="width: 85%; margin-left: 7.5%" v-if="sortedSyllableList.length > 0">

          <tr>
            <th style="width: 25%; text-align: right; padding-right: 15px;">SRO</th>
            <th style="width: 75%; padding-left: 20px; text-align: left">Details</th>
          </tr>

          <tr v-for="(syllable, s) in sortedSyllableList" :key="'syllableList' + s">
            <td  style="text-align: right; font-weight: bold; padding-right: 15px; padding-top: 20px; vertical-align: top;">
              {{ syllable.sro }}
            </td>

            <td>
              
              <table class="va-table" style="width: 500px">
                <tr>
                  <td style="padding-bottom: 5px; height: 20px; font-size: 10px; width: 250px;">Syllable</td>
                </tr>
                <tr>
                  <td style="padding-top: 0px; width: 250px;"> {{ syllable.syllable }}</td>
                </tr>
            
                <tr>
                  <td style="padding-bottom: 5px; height: 20px; font-size: 10px; width: 250px;">My Audio</td>
                </tr>
                <tr>
                  <td style="padding-top: 0px; width: 350px;"> 
                    
                    <va-button
                      icon="volume_up"
                      size="large"
                      :rounded="false"
                      style="width: 200px; height: 50px" v-if="userStore.mySpeakerSyllables[syllable.sro]" @click="playAudioFile(userStore.mySpeakerSyllables[syllable.sro])">
                    </va-button>
                    <div v-else style="color: red">Not Yet Recorded</div>
                  </td>
                </tr>

                <tr>
                  <td style="padding-bottom: 5px; height: 20px; font-size: 10px; width: 250px;">Record Audio</td>
                </tr>
                <tr>
                    <td style="padding-top: 0px; width: 250px;"> 
                    <va-button 
                        :rounded="false"
                        color="#000"
                        outline
                        size="large"
                        icon="mic"
                        v-if="recordingAudio == false"
                        style="width: 200px; height: 50px"  @click="recordAudioFile(syllable.sro,'syllable')">
                    </va-button>

                    <va-button
                        v-else-if="activeAudioWord == syllable.sro"
                        :rounded="false"
                        color="danger"
                        size="small"
                        icon="stop"
                        style="width: 75px;" @click="stopAudioRecording()">
                    </va-button>
                  </td>
                </tr>


              </table>
            </td>

          </tr>
        </table>
      
      
      
      </template>

      </template>

    
  </div>

  
</template>

<script>



import { Storage } from 'aws-amplify';
import { useAuth } from '@/stores/Auth/auth'
import { useWords } from '@/stores/Words/words'
import { useUsers } from '@/stores/Users/users'
import { reactive, ref, computed, watch } from 'vue';
import IPA from "@/assets/ipa.js";
import { useColors } from 'vuestic-ui';
import MicRecorder from 'mic-recorder-to-mp3'


export default {
  name: 'SpeakerPage',
  components: {
  },
  props: {
  },

  setup(){


    const { getColors } = useColors()
    const colors = computed(getColors)

    const loading = ref(true);

    const authStore = useAuth();
    const wordStore = useWords();
    const userStore = useUsers();

    wordStore.listAllWords();
    wordStore.listAllMorphemes();
    wordStore.listAllSyllables();

    let syllabics = reactive(IPA.syllabics);

    const section = ref("Needed Words");
    const selectedMorpheme = ref("");
    const filterWordListLetter = ref("");

    
    watch(filterWordListLetter, async () => {
      selectedMorpheme.value = morphemeOptions_filtered.value[0];
    })

    const activeSyllable = ref(null); 
    const showCwV = ref(false);

    let showModal = ref(false);
    let modalMessage = ref(null);
    let updatingWord = ref(false);

    const recordingAudio = ref(false);
    const activeAudioWord = ref("");
    const activeAudioMode = ref("");
    let showAudioRecorder = ref(false);

    const soundEffects = reactive({
      'activeWord': { path: null, src: null, audio: null},
    })

    const soundEffectsActivated = ref(false);



    const types = reactive({
      "IPC": { name: "Independent particle", Description: "Often specifying the time, place, manner etc of a verb" },
      "IPH": { name:  "Set particle phrase", Description: "" },
      "IPV": { name:  "Preverb", Description: "" },
      "IPN": { name:  "Prenoun", Description: "" },
      "IPP": { name:  "Pre-particle", Description: "" },
      "NA": { name:  "Animate noun", Description: "" },
      "NI": { name:  "Inaminate noun", Description: "" },
      "INM": { name:  "Indeclinable nominal", Description: "" },
      "NDA": { name:  "Animate depednent noun", Description: "" },
      "NDI": { name:  "Inanimate dependent noun", Description: "" },
      "PR": { name:  "Pronoun", Description: "" },
      "VII": { name:  "Inanimate intransitive verb", Description: "" },
      "VAI": { name:  "Animate intransitive verb", Description: "" },
      "VTI": { name:  "Inanimate transitive verb", Description: "" },
      "VTA": { name:  "Animate transitive verb", Description: "" },
      "VAIt": { name:  "", Description: "" },
    });

    const typeOptions = [
      'IPC','IPH','IPV','IPN','IPP','NA','NI','NDA','PR','VII','VAI','VTI','VTA','VAIt'
    ]

    const topWordList = ref([]);

  
    const signOut = () => {
      authStore.logout();
    }

    const recorder = new MicRecorder({
        bitRate: 128
    });

    const playSound = async (soundObject) => {
      if(soundObject.audio == null) return
      soundObject.audio.src = soundObject.src.src;
      await soundObject.audio.play();
    }

    const recordAudioFile = async (sro, type) => {

        console.log(sro, type);
        activeAudioWord.value = sro;
        activeAudioMode.value = type;
        recordingAudio.value = true;

        recorder.start().then(() => {
            console.log('recording');
            
        }).catch((e) => {
        console.error(e);
        });
        
    };

    const stopAudioRecording = () => {
        console.log('stop');
        recordingAudio.value = false;
        
       

        recorder
            .stop()
            .getMp3().then(async ([buffer, blob]) => {
            // do what ever you want with buffer and blob
            // Example: Create a mp3 file and play
            const file = new File(buffer, activeAudioWord.value + '.mp3', {
                type: blob.type,
                lastModified: Date.now()
            });
            
            const upload = await Storage.put(activeAudioWord.value + '.mp3', file, {
              level: "protected",
              contentType: "audio/mp3",
              progressCallback(progress) {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
              },
            });

            if(activeAudioMode.value == "word")
            {
              if(Object.prototype.hasOwnProperty.call(userStore.mySpeakerWords, activeAudioWord.value))
                {
                  // update
                  userStore.updateSpeakerWord({
                    sro: activeAudioWord.value,
                    audio:{
                      bucket: 'nehiyawewina6a788ea40fe4c81a3f49ae70ad9e9c8135855-dev',
                      region: 'ca-central-1',
                      key: upload.key,
                    }
                  })
                } else
                {
                  //put object to s3
                  userStore.createSpeakerWord({
                    userID: userStore.userData.id,
                    userSpeaker_wordsId: userStore.userData.id,
                    bucketID: authStore.user.id,
                    sro: activeAudioWord.value,
                    audio:{
                      bucket: 'nehiyawewina6a788ea40fe4c81a3f49ae70ad9e9c8135855-dev',
                      region: 'ca-central-1',
                      key: upload.key,
                    }
                  })
                }
            }
            else if(activeAudioMode.value == "syllable")
            {
              if(Object.prototype.hasOwnProperty.call(userStore.mySpeakerSyllables, activeAudioWord.value))
                {
                  userStore.updateSpeakerSyllable({
                    sro: activeAudioWord.value,
                    audio:{
                      bucket: 'nehiyawewina6a788ea40fe4c81a3f49ae70ad9e9c8135855-dev',
                      region: 'ca-central-1',
                      key: upload.key,
                    }
                  })
                } else
                {
                  userStore.createSpeakerSyllable({
                    userID: userStore.userData.id,
                    userSpeaker_syllablesId: userStore.userData.id,
                    bucketID: authStore.user.id,
                    sro: activeAudioWord.value,
                    audio:{
                      bucket: 'nehiyawewina6a788ea40fe4c81a3f49ae70ad9e9c8135855-dev',
                      region: 'ca-central-1',
                      key: upload.key,
                    }
                  })
                }
            }

  
            
            activeAudioWord.value = "";

            soundEffects['activeWord'].src = new Audio(URL.createObjectURL(file));
            await playSound(soundEffects['activeWord']);
            
            }).catch((e) => {
            alert('We could not retrieve your message');
            activeAudioWord.value = "";
            console.log(e);
        });

        
    };



    const playAudioFile = async (word) => {
    
      let signedURL = await Storage.get(word.audio.key, {
          level: 'protected',
          identityId: word.bucketID,
          expires: 100,
          contentType: "audio/mp3"
        });

      try{
        soundEffects['activeWord'].src = new Audio(signedURL);
        await playSound(soundEffects['activeWord']);
      } catch(err)
      {
        console.log(err);
        showModal = true;
        modalMessage = "File not found - try waiting a few seconds if this word was just created"
      }
    }

    const getTopWordList = async () => {
            
      let list = [];
      if(wordStore.words == null) return list

      for(let word in wordStore.words)
      {
        if(!userStore.mySpeakerWords[word]) list.push(wordStore.words[word]);
      }

      list.sort(function(a,b){
        let letter1 = a.sro[0];
        let letter2 = b.sro[0];

        let index1 = morphemeLetterOptions.value.indexOf(letter1);
        let index2 = morphemeLetterOptions.value.indexOf(letter2);
          
        if(a.level > b.level) return 1
        else if(a.level < b.level) return -1
        else if(index1 > index2) return 1
        else if(index1 < index2) return -1
        else return 0
      })

      topWordList.value = list.slice(0,10);
      return

    }


    const morphemeOptions = computed( () =>{

      let list = [];
      if(wordStore.morphemes == null) return list

      for(let morpheme in wordStore.morphemes)
      {
        list.push(wordStore.morphemes[morpheme].sro);
      }

      list.sort(function(a,b){
        if(a > b) return 1
        else if(a < b) return -1
        else return 0
      })

      return list
    })

    const morphemeOptions_filtered = computed( () =>{

      let list = [];
      if(wordStore.morphemes == null) return list

      for(let morpheme in wordStore.morphemes)
      {
        if(filterWordListLetter.value != "" && wordStore.morphemes[morpheme].sro[0] != filterWordListLetter.value) continue
        list.push(wordStore.morphemes[morpheme].sro);
      }

      list.sort(function(a,b){
        if(a > b) return 1
        else if(a < b) return -1
        else return 0
      })

      return list
    })

    const morphemeLetterOptions = ref([
      'a','ā','c','ē','i','ī','k','m','n','o','ō','p','s','t','w','y'
    ])

    const sortedSyllableList = computed( () => {
      
      let list = [];
      if(wordStore.syllables == null) return list

      for(let syllable in wordStore.syllables)
      {
        list.push(wordStore.syllables[syllable]);
      }

      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })

      return list

    })

    const sortedWordList = computed( () => {
      
      let list = [];
      if(wordStore.words == null) return list

      for(let word in wordStore.words)
      {
        if(wordStore.words[word].morphemeWordsId != selectedMorpheme.value) continue

        list.push(wordStore.words[word]);
      }

      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })

      console.log(list);
      return list

    })

    
    
    const sortedMorphemeList = computed( () => {
      
      let list = [];
      if(wordStore.morphemes == null) return list

      for(let morpheme in wordStore.morphemes)
      {
        list.push(wordStore.morphemes[morpheme]);
      }

      list.sort(function(a,b){
        if(a.sro > b.sro) return 1
        else if(a.sro < b.sro) return -1
        else return 0
      })

      return list

    })


    return {
      loading,
      authStore,
      wordStore,
      userStore,
      section,
      activeSyllable,
      showCwV,
      selectedMorpheme,
      syllabics,
      updatingWord,
      types,
      typeOptions,
      colors,
      showModal,
      modalMessage,
      showAudioRecorder,
      recordingAudio,
      activeAudioWord,
      filterWordListLetter,
      morphemeOptions,
      morphemeOptions_filtered,
      morphemeLetterOptions,
      sortedSyllableList,
      sortedWordList,
      sortedMorphemeList,
      soundEffectsActivated,
      soundEffects,
      topWordList,
      getTopWordList,
      signOut,
      recordAudioFile,
      stopAudioRecording,
      playAudioFile,
    }
  },

  async mounted(){
    
    window.addEventListener('click', () => {
      if(this.soundEffectsActivated == false)
      {
        for(let effect in this.soundEffects){
          this.soundEffects[effect].audio = new Audio();
        }   
        this.soundEffectsActivated = true;
      }
    })


    await this.authStore.authAction();
    await this.wordStore.setupSubscriptions();

    await this.userStore.getUser(this.authStore.userAuthDetails.attributes.sub);
    await this.userStore.getSpeakerWords(this.authStore.userAuthDetails.attributes.sub);
    await this.userStore.getSpeakerSyllables(this.authStore.userAuthDetails.attributes.sub);



    this.selectedMorpheme = this.morphemeOptions[0];
    this.filterWordListLetter = this.morphemeLetterOptions[0];

    this.getTopWordList();

    this.loading = false;
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
#wordListTable {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

#wordListTable td {
  width: 250px;
  height: 20px !important;
  max-height: 20px !important;
  vertical-align: middle;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
