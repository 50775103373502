/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:add0caec-3be2-4a90-ba49-7ededbaea75b",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_bwli5C0Mh",
    "aws_user_pools_web_client_id": "73i3qhfpgoui4gk2v0qt0euv9b",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://5eu45zlmgncfpgsr2l7n7ybu6m.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wytimesxtnautdjqx5mmkrgj4y",
    "aws_user_files_s3_bucket": "nehiyawewina6a788ea40fe4c81a3f49ae70ad9e9c8135855-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
