/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSyllable = /* GraphQL */ `
  mutation CreateSyllable(
    $input: CreateSyllableInput!
    $condition: ModelSyllableConditionInput
  ) {
    createSyllable(input: $input, condition: $condition) {
      sro
      syllable
      CwV
      createdAt
      updatedAt
    }
  }
`;
export const updateSyllable = /* GraphQL */ `
  mutation UpdateSyllable(
    $input: UpdateSyllableInput!
    $condition: ModelSyllableConditionInput
  ) {
    updateSyllable(input: $input, condition: $condition) {
      sro
      syllable
      CwV
      createdAt
      updatedAt
    }
  }
`;
export const deleteSyllable = /* GraphQL */ `
  mutation DeleteSyllable(
    $input: DeleteSyllableInput!
    $condition: ModelSyllableConditionInput
  ) {
    deleteSyllable(input: $input, condition: $condition) {
      sro
      syllable
      CwV
      createdAt
      updatedAt
    }
  }
`;
export const createWord = /* GraphQL */ `
  mutation CreateWord(
    $input: CreateWordInput!
    $condition: ModelWordConditionInput
  ) {
    createWord(input: $input, condition: $condition) {
      sro
      definition
      type
      syllabics
      num_syllables
      morpheme {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      level
      dependencies
      notes
      image_url
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const updateWord = /* GraphQL */ `
  mutation UpdateWord(
    $input: UpdateWordInput!
    $condition: ModelWordConditionInput
  ) {
    updateWord(input: $input, condition: $condition) {
      sro
      definition
      type
      syllabics
      num_syllables
      morpheme {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      level
      dependencies
      notes
      image_url
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const deleteWord = /* GraphQL */ `
  mutation DeleteWord(
    $input: DeleteWordInput!
    $condition: ModelWordConditionInput
  ) {
    deleteWord(input: $input, condition: $condition) {
      sro
      definition
      type
      syllabics
      num_syllables
      morpheme {
        sro
        definition
        syllabics
        level
        active
        words {
          nextToken
        }
        createdAt
        updatedAt
      }
      level
      dependencies
      notes
      image_url
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const createWordCount = /* GraphQL */ `
  mutation CreateWordCount(
    $input: CreateWordCountInput!
    $condition: ModelWordCountConditionInput
  ) {
    createWordCount(input: $input, condition: $condition) {
      level
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateWordCount = /* GraphQL */ `
  mutation UpdateWordCount(
    $input: UpdateWordCountInput!
    $condition: ModelWordCountConditionInput
  ) {
    updateWordCount(input: $input, condition: $condition) {
      level
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteWordCount = /* GraphQL */ `
  mutation DeleteWordCount(
    $input: DeleteWordCountInput!
    $condition: ModelWordCountConditionInput
  ) {
    deleteWordCount(input: $input, condition: $condition) {
      level
      count
      createdAt
      updatedAt
    }
  }
`;
export const createMorpheme = /* GraphQL */ `
  mutation CreateMorpheme(
    $input: CreateMorphemeInput!
    $condition: ModelMorphemeConditionInput
  ) {
    createMorpheme(input: $input, condition: $condition) {
      sro
      definition
      syllabics
      level
      active
      words {
        items {
          sro
          definition
          type
          syllabics
          num_syllables
          level
          dependencies
          notes
          image_url
          createdAt
          updatedAt
          morphemeWordsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMorpheme = /* GraphQL */ `
  mutation UpdateMorpheme(
    $input: UpdateMorphemeInput!
    $condition: ModelMorphemeConditionInput
  ) {
    updateMorpheme(input: $input, condition: $condition) {
      sro
      definition
      syllabics
      level
      active
      words {
        items {
          sro
          definition
          type
          syllabics
          num_syllables
          level
          dependencies
          notes
          image_url
          createdAt
          updatedAt
          morphemeWordsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMorpheme = /* GraphQL */ `
  mutation DeleteMorpheme(
    $input: DeleteMorphemeInput!
    $condition: ModelMorphemeConditionInput
  ) {
    deleteMorpheme(input: $input, condition: $condition) {
      sro
      definition
      syllabics
      level
      active
      words {
        items {
          sro
          definition
          type
          syllabics
          num_syllables
          level
          dependencies
          notes
          image_url
          createdAt
          updatedAt
          morphemeWordsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user_name
      name
      location
      speaker
      requesting_speaker
      words {
        items {
          sro
          userID
          bucketID
          level
          xp
          user_confirmed
          recent_attempt_quantity
          recent_attempt_percentage
          recent_attempt_history
          createdAt
          updatedAt
          userWordsId
        }
        nextToken
      }
      speaker_words {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_wordsId
        }
        nextToken
      }
      syllables {
        items {
          sro
          userID
          bucketID
          user_confirmed
          createdAt
          updatedAt
          userSyllablesId
        }
        nextToken
      }
      speaker_syllables {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_syllablesId
        }
        nextToken
      }
      level
      xp
      wordPoints {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        points
        createdAt
        updatedAt
        userWordPointUserId
      }
      requests {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        unlock_words
        unlock_phrase
        confirm_word
        set_default_speaker
        start_training
        submit_answer
        answer_string
        next_session
        end_session
        change_name
        change_location
        request_speaker_access
        request_hint
        request_hint_working_answer
        createdAt
        updatedAt
        userRequestUserId
      }
      level_status {
        items {
          userID
          level
          intro_complete
          createdAt
          updatedAt
          userLevel_statusId
        }
        nextToken
      }
      defaultSpeakerID
      trainingInstance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      most_recent_word
      createdAt
      updatedAt
      userWordPointsId
      userRequestsId
      userTrainingInstanceId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_name
      name
      location
      speaker
      requesting_speaker
      words {
        items {
          sro
          userID
          bucketID
          level
          xp
          user_confirmed
          recent_attempt_quantity
          recent_attempt_percentage
          recent_attempt_history
          createdAt
          updatedAt
          userWordsId
        }
        nextToken
      }
      speaker_words {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_wordsId
        }
        nextToken
      }
      syllables {
        items {
          sro
          userID
          bucketID
          user_confirmed
          createdAt
          updatedAt
          userSyllablesId
        }
        nextToken
      }
      speaker_syllables {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_syllablesId
        }
        nextToken
      }
      level
      xp
      wordPoints {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        points
        createdAt
        updatedAt
        userWordPointUserId
      }
      requests {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        unlock_words
        unlock_phrase
        confirm_word
        set_default_speaker
        start_training
        submit_answer
        answer_string
        next_session
        end_session
        change_name
        change_location
        request_speaker_access
        request_hint
        request_hint_working_answer
        createdAt
        updatedAt
        userRequestUserId
      }
      level_status {
        items {
          userID
          level
          intro_complete
          createdAt
          updatedAt
          userLevel_statusId
        }
        nextToken
      }
      defaultSpeakerID
      trainingInstance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      most_recent_word
      createdAt
      updatedAt
      userWordPointsId
      userRequestsId
      userTrainingInstanceId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user_name
      name
      location
      speaker
      requesting_speaker
      words {
        items {
          sro
          userID
          bucketID
          level
          xp
          user_confirmed
          recent_attempt_quantity
          recent_attempt_percentage
          recent_attempt_history
          createdAt
          updatedAt
          userWordsId
        }
        nextToken
      }
      speaker_words {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_wordsId
        }
        nextToken
      }
      syllables {
        items {
          sro
          userID
          bucketID
          user_confirmed
          createdAt
          updatedAt
          userSyllablesId
        }
        nextToken
      }
      speaker_syllables {
        items {
          sro
          userID
          bucketID
          createdAt
          updatedAt
          userSpeaker_syllablesId
        }
        nextToken
      }
      level
      xp
      wordPoints {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        points
        createdAt
        updatedAt
        userWordPointUserId
      }
      requests {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        unlock_words
        unlock_phrase
        confirm_word
        set_default_speaker
        start_training
        submit_answer
        answer_string
        next_session
        end_session
        change_name
        change_location
        request_speaker_access
        request_hint
        request_hint_working_answer
        createdAt
        updatedAt
        userRequestUserId
      }
      level_status {
        items {
          userID
          level
          intro_complete
          createdAt
          updatedAt
          userLevel_statusId
        }
        nextToken
      }
      defaultSpeakerID
      trainingInstance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      most_recent_word
      createdAt
      updatedAt
      userWordPointsId
      userRequestsId
      userTrainingInstanceId
    }
  }
`;
export const createUserWordPoint = /* GraphQL */ `
  mutation CreateUserWordPoint(
    $input: CreateUserWordPointInput!
    $condition: ModelUserWordPointConditionInput
  ) {
    createUserWordPoint(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      points
      createdAt
      updatedAt
      userWordPointUserId
    }
  }
`;
export const updateUserWordPoint = /* GraphQL */ `
  mutation UpdateUserWordPoint(
    $input: UpdateUserWordPointInput!
    $condition: ModelUserWordPointConditionInput
  ) {
    updateUserWordPoint(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      points
      createdAt
      updatedAt
      userWordPointUserId
    }
  }
`;
export const deleteUserWordPoint = /* GraphQL */ `
  mutation DeleteUserWordPoint(
    $input: DeleteUserWordPointInput!
    $condition: ModelUserWordPointConditionInput
  ) {
    deleteUserWordPoint(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      points
      createdAt
      updatedAt
      userWordPointUserId
    }
  }
`;
export const createUserRequest = /* GraphQL */ `
  mutation CreateUserRequest(
    $input: CreateUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    createUserRequest(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      unlock_words
      unlock_phrase
      confirm_word
      set_default_speaker
      start_training
      submit_answer
      answer_string
      next_session
      end_session
      change_name
      change_location
      request_speaker_access
      request_hint
      request_hint_working_answer
      createdAt
      updatedAt
      userRequestUserId
    }
  }
`;
export const updateUserRequest = /* GraphQL */ `
  mutation UpdateUserRequest(
    $input: UpdateUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    updateUserRequest(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      unlock_words
      unlock_phrase
      confirm_word
      set_default_speaker
      start_training
      submit_answer
      answer_string
      next_session
      end_session
      change_name
      change_location
      request_speaker_access
      request_hint
      request_hint_working_answer
      createdAt
      updatedAt
      userRequestUserId
    }
  }
`;
export const deleteUserRequest = /* GraphQL */ `
  mutation DeleteUserRequest(
    $input: DeleteUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    deleteUserRequest(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      unlock_words
      unlock_phrase
      confirm_word
      set_default_speaker
      start_training
      submit_answer
      answer_string
      next_session
      end_session
      change_name
      change_location
      request_speaker_access
      request_hint
      request_hint_working_answer
      createdAt
      updatedAt
      userRequestUserId
    }
  }
`;
export const createTrainingInstance = /* GraphQL */ `
  mutation CreateTrainingInstance(
    $input: CreateTrainingInstanceInput!
    $condition: ModelTrainingInstanceConditionInput
  ) {
    createTrainingInstance(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      definition
      num_syllables
      num_letters
      word_level
      answer {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      component_results
      clue_audio_bucketID
      clue_audio_key
      clue_audio_user_id
      space_locations
      hints
      type
      createdAt
      updatedAt
      trainingInstanceAnswerId
      trainingInstanceUserId
    }
  }
`;
export const updateTrainingInstance = /* GraphQL */ `
  mutation UpdateTrainingInstance(
    $input: UpdateTrainingInstanceInput!
    $condition: ModelTrainingInstanceConditionInput
  ) {
    updateTrainingInstance(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      definition
      num_syllables
      num_letters
      word_level
      answer {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      component_results
      clue_audio_bucketID
      clue_audio_key
      clue_audio_user_id
      space_locations
      hints
      type
      createdAt
      updatedAt
      trainingInstanceAnswerId
      trainingInstanceUserId
    }
  }
`;
export const deleteTrainingInstance = /* GraphQL */ `
  mutation DeleteTrainingInstance(
    $input: DeleteTrainingInstanceInput!
    $condition: ModelTrainingInstanceConditionInput
  ) {
    deleteTrainingInstance(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      definition
      num_syllables
      num_letters
      word_level
      answer {
        instanceID
        answer
        instance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        createdAt
        updatedAt
        trainingInstanceAnswerInstanceId
      }
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      time_bonus
      syllabics_bonus
      word_xp_awarded
      user_xp_awarded
      component_results
      clue_audio_bucketID
      clue_audio_key
      clue_audio_user_id
      space_locations
      hints
      type
      createdAt
      updatedAt
      trainingInstanceAnswerId
      trainingInstanceUserId
    }
  }
`;
export const createTrainingInstanceAnswer = /* GraphQL */ `
  mutation CreateTrainingInstanceAnswer(
    $input: CreateTrainingInstanceAnswerInput!
    $condition: ModelTrainingInstanceAnswerConditionInput
  ) {
    createTrainingInstanceAnswer(input: $input, condition: $condition) {
      instanceID
      answer
      instance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      createdAt
      updatedAt
      trainingInstanceAnswerInstanceId
    }
  }
`;
export const updateTrainingInstanceAnswer = /* GraphQL */ `
  mutation UpdateTrainingInstanceAnswer(
    $input: UpdateTrainingInstanceAnswerInput!
    $condition: ModelTrainingInstanceAnswerConditionInput
  ) {
    updateTrainingInstanceAnswer(input: $input, condition: $condition) {
      instanceID
      answer
      instance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      createdAt
      updatedAt
      trainingInstanceAnswerInstanceId
    }
  }
`;
export const deleteTrainingInstanceAnswer = /* GraphQL */ `
  mutation DeleteTrainingInstanceAnswer(
    $input: DeleteTrainingInstanceAnswerInput!
    $condition: ModelTrainingInstanceAnswerConditionInput
  ) {
    deleteTrainingInstanceAnswer(input: $input, condition: $condition) {
      instanceID
      answer
      instance {
        userID
        user {
          id
          user_name
          name
          location
          speaker
          requesting_speaker
          level
          xp
          defaultSpeakerID
          most_recent_word
          createdAt
          updatedAt
          userWordPointsId
          userRequestsId
          userTrainingInstanceId
        }
        definition
        num_syllables
        num_letters
        word_level
        answer {
          instanceID
          answer
          createdAt
          updatedAt
          trainingInstanceAnswerInstanceId
        }
        status
        availableSyllables
        submitted_answer
        correct_answer
        total_millis
        time_bonus
        syllabics_bonus
        word_xp_awarded
        user_xp_awarded
        component_results
        clue_audio_bucketID
        clue_audio_key
        clue_audio_user_id
        space_locations
        hints
        type
        createdAt
        updatedAt
        trainingInstanceAnswerId
        trainingInstanceUserId
      }
      createdAt
      updatedAt
      trainingInstanceAnswerInstanceId
    }
  }
`;
export const createUserWord = /* GraphQL */ `
  mutation CreateUserWord(
    $input: CreateUserWordInput!
    $condition: ModelUserWordConditionInput
  ) {
    createUserWord(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      level
      xp
      user_confirmed
      recent_attempt_quantity
      recent_attempt_percentage
      recent_attempt_history
      createdAt
      updatedAt
      userWordsId
    }
  }
`;
export const updateUserWord = /* GraphQL */ `
  mutation UpdateUserWord(
    $input: UpdateUserWordInput!
    $condition: ModelUserWordConditionInput
  ) {
    updateUserWord(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      level
      xp
      user_confirmed
      recent_attempt_quantity
      recent_attempt_percentage
      recent_attempt_history
      createdAt
      updatedAt
      userWordsId
    }
  }
`;
export const deleteUserWord = /* GraphQL */ `
  mutation DeleteUserWord(
    $input: DeleteUserWordInput!
    $condition: ModelUserWordConditionInput
  ) {
    deleteUserWord(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      level
      xp
      user_confirmed
      recent_attempt_quantity
      recent_attempt_percentage
      recent_attempt_history
      createdAt
      updatedAt
      userWordsId
    }
  }
`;
export const createSpeakerWord = /* GraphQL */ `
  mutation CreateSpeakerWord(
    $input: CreateSpeakerWordInput!
    $condition: ModelSpeakerWordConditionInput
  ) {
    createSpeakerWord(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_wordsId
    }
  }
`;
export const updateSpeakerWord = /* GraphQL */ `
  mutation UpdateSpeakerWord(
    $input: UpdateSpeakerWordInput!
    $condition: ModelSpeakerWordConditionInput
  ) {
    updateSpeakerWord(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_wordsId
    }
  }
`;
export const deleteSpeakerWord = /* GraphQL */ `
  mutation DeleteSpeakerWord(
    $input: DeleteSpeakerWordInput!
    $condition: ModelSpeakerWordConditionInput
  ) {
    deleteSpeakerWord(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_wordsId
    }
  }
`;
export const createUserSyllable = /* GraphQL */ `
  mutation CreateUserSyllable(
    $input: CreateUserSyllableInput!
    $condition: ModelUserSyllableConditionInput
  ) {
    createUserSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      user_confirmed
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;
export const updateUserSyllable = /* GraphQL */ `
  mutation UpdateUserSyllable(
    $input: UpdateUserSyllableInput!
    $condition: ModelUserSyllableConditionInput
  ) {
    updateUserSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      user_confirmed
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;
export const deleteUserSyllable = /* GraphQL */ `
  mutation DeleteUserSyllable(
    $input: DeleteUserSyllableInput!
    $condition: ModelUserSyllableConditionInput
  ) {
    deleteUserSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      user_confirmed
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;
export const createSpeakerSyllable = /* GraphQL */ `
  mutation CreateSpeakerSyllable(
    $input: CreateSpeakerSyllableInput!
    $condition: ModelSpeakerSyllableConditionInput
  ) {
    createSpeakerSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const updateSpeakerSyllable = /* GraphQL */ `
  mutation UpdateSpeakerSyllable(
    $input: UpdateSpeakerSyllableInput!
    $condition: ModelSpeakerSyllableConditionInput
  ) {
    updateSpeakerSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const deleteSpeakerSyllable = /* GraphQL */ `
  mutation DeleteSpeakerSyllable(
    $input: DeleteSpeakerSyllableInput!
    $condition: ModelSpeakerSyllableConditionInput
  ) {
    deleteSpeakerSyllable(input: $input, condition: $condition) {
      sro
      userID
      bucketID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      audio {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      userSpeaker_syllablesId
    }
  }
`;
export const createLevel = /* GraphQL */ `
  mutation CreateLevel(
    $input: CreateLevelInput!
    $condition: ModelLevelConditionInput
  ) {
    createLevel(input: $input, condition: $condition) {
      level
      notes
      priority_words
      createdAt
      updatedAt
    }
  }
`;
export const updateLevel = /* GraphQL */ `
  mutation UpdateLevel(
    $input: UpdateLevelInput!
    $condition: ModelLevelConditionInput
  ) {
    updateLevel(input: $input, condition: $condition) {
      level
      notes
      priority_words
      createdAt
      updatedAt
    }
  }
`;
export const deleteLevel = /* GraphQL */ `
  mutation DeleteLevel(
    $input: DeleteLevelInput!
    $condition: ModelLevelConditionInput
  ) {
    deleteLevel(input: $input, condition: $condition) {
      level
      notes
      priority_words
      createdAt
      updatedAt
    }
  }
`;
export const createUserLevel = /* GraphQL */ `
  mutation CreateUserLevel(
    $input: CreateUserLevelInput!
    $condition: ModelUserLevelConditionInput
  ) {
    createUserLevel(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      level
      intro_complete
      createdAt
      updatedAt
      userLevel_statusId
    }
  }
`;
export const updateUserLevel = /* GraphQL */ `
  mutation UpdateUserLevel(
    $input: UpdateUserLevelInput!
    $condition: ModelUserLevelConditionInput
  ) {
    updateUserLevel(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      level
      intro_complete
      createdAt
      updatedAt
      userLevel_statusId
    }
  }
`;
export const deleteUserLevel = /* GraphQL */ `
  mutation DeleteUserLevel(
    $input: DeleteUserLevelInput!
    $condition: ModelUserLevelConditionInput
  ) {
    deleteUserLevel(input: $input, condition: $condition) {
      userID
      user {
        id
        user_name
        name
        location
        speaker
        requesting_speaker
        words {
          nextToken
        }
        speaker_words {
          nextToken
        }
        syllables {
          nextToken
        }
        speaker_syllables {
          nextToken
        }
        level
        xp
        wordPoints {
          userID
          points
          createdAt
          updatedAt
          userWordPointUserId
        }
        requests {
          userID
          unlock_words
          unlock_phrase
          confirm_word
          set_default_speaker
          start_training
          submit_answer
          answer_string
          next_session
          end_session
          change_name
          change_location
          request_speaker_access
          request_hint
          request_hint_working_answer
          createdAt
          updatedAt
          userRequestUserId
        }
        level_status {
          nextToken
        }
        defaultSpeakerID
        trainingInstance {
          userID
          definition
          num_syllables
          num_letters
          word_level
          status
          availableSyllables
          submitted_answer
          correct_answer
          total_millis
          time_bonus
          syllabics_bonus
          word_xp_awarded
          user_xp_awarded
          component_results
          clue_audio_bucketID
          clue_audio_key
          clue_audio_user_id
          space_locations
          hints
          type
          createdAt
          updatedAt
          trainingInstanceAnswerId
          trainingInstanceUserId
        }
        most_recent_word
        createdAt
        updatedAt
        userWordPointsId
        userRequestsId
        userTrainingInstanceId
      }
      level
      intro_complete
      createdAt
      updatedAt
      userLevel_statusId
    }
  }
`;
