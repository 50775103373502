<template>
  <div>

  <va-modal v-model="showModal" :message="modalMessage"  />


  <!-- card with table featuring user name, name, loction, speaker sign up option -->



    <h3 style="font-size: 20px; margin-top: 15px; margin-bottom: 10px">My Profile</h3>
    <div style="width: 95%; margin-left: 2.5%; margin-top: 15px; margin-bottom: 15px; padding-bottom: 75px; display: flex; flex-wrap: wrap; justify-content: flex-start" v-if="userStore.userData">

    

        <div style="width: 25%; margin-bottom: 5px; text-align: left">Name:</div>
  
      
        <va-input
          @change="updateUser('name')"
          style="width: 75%; margin-top: 5px; margin-bottom: 25px; width: 100%"
          v-model="userStore.userData.name"
          placeholder=""
        ></va-input>

    

        <div style="width: 25%; margin-bottom: 5px; text-align: left">Location:</div>

        <va-input
          @change="updateUser('location')"
          style="width: 75%; margin-top: 5px; margin-bottom: 25px; width: 100%"
          v-model="userStore.userData.location"
          placeholder=""
        ></va-input>      
              
        <div style="width:100%">
        <table style="width: 250px; max-width: 250px; margin: 0 auto">

        

          <tr>
            <td colspan='3' style="text-align: center; padding-bottom: 35px; font-size: 18px; font-weight: bold">My Words & Phrases</td>
          </tr>
          <tr>
            
            <!-- <td style="width: 150px">&nbsp;</td> -->
            <td style="width: 35px">&nbsp;</td>
            <td style="text-align: center; padding-bottom: 2px; border-bottom: 1px solid rgba(137,137,137,.6); font-weight: bold">This Level</td>
            <td style="text-align: center; padding-bottom: 2px; border-bottom: 1px solid rgba(137,137,137,.6); font-weight: bold">Total</td>
          </tr>
          
          
          <tr v-for="l in 6" :key="'wordLevelRow' + l">

            <!-- <td style="text-align: right; padding-right: 5px; width: 150px;">Word Level {{ l }}</td> -->
            <td style="text-align: right; width: 35px; padding-right: 15px;">
            <div
              class="ball mainfloat"
              :style="{ 
                backgroundColor:  wordLevelColours[l],
                backgroundSize:  '15px', 
                height: '15px', 
                width: '15px', 
                transform: 'translateY(5px)',
                boxShadow:      
                      'inset 0 0 ' + (15 * .33) + 'px #FFF,' + 
                      '0 0 ' + (15 * pulseValue) + 'px ' + wordLevelColours[l],

                }" 
              > 
              </div>
            </td>
            <td style="text-align: center; "> {{ totalWords(userStore.userData.level).learned[l] }}</td>
            <td style="text-align: center; "> {{ totalWords().learned[l] }}</td>
          </tr>

          <tr>
            
            <td style="text-align: right; padding-top: 15px; padding-right: 5px; width: 35px; font-weight: bold"></td>
            <!-- <td style="width: 35px">&nbsp;</td> -->
            <td style="text-align: center; padding-top: 15px; font-weight: bold"> {{ totalWords(userStore.userData.level).learned['total'] }}  ({{  Math.round(totalWords(userStore.userData.level).learned['total'] / totalWords(userStore.userData.level).available['total'] * 100) }}%)</td>
            <td style="text-align: center; padding-top: 15px; font-weight: bold"> {{ totalWords().learned['total'] }} ({{  Math.round(totalWords().learned['total'] / totalWords().available['total'] * 100) }}%)</td>
          </tr>

   
          
        </table>
      </div>

      <va-button size="large" color="rgb(49,20,50)" style="margin: 0 auto; margin-top: 25px;"  v-if="authStore && authStore.user && authStore.userGroups && authStore.userGroups.includes('Users')" @click="signOut()">Sign Out</va-button>
        
  

    </div>
    
  

 
  </div>
    

</template>

<script>



import { reactive, ref, computed, watch } from 'vue';
import { useColors } from 'vuestic-ui';
import { useUsers } from '@/stores/Users/users'
import { useAuth } from '@/stores/Auth/auth'
import { useWords } from '@/stores/Words/words'
import router from "@/router";



export default {
  name: 'ProfilePage',

  setup(){


    const { getColors } = useColors()
    const colors = computed(getColors)

    const userStore = useUsers();
    const authStore = useAuth();
    const wordStore = useWords();

    const showModal = ref(false);
    const modalMessage = ref(null);

    const pulseValue = ref(0);
    const pulseIncrement = ref(0.01);

    const wordLevelColours = reactive({
      1: '#82BA6F',
      2: '#54A1B9',
      3: '#7D409A',
      4: '#F7954A',
      5: '#F33645',
      6: '#000',
    });
   

    const request_speaker_access = computed( () =>{

      if(userStore.userData)
      {
        return userStore.userData.requesting_speaker
      }

      return null

    })

    const signOut = async () => {

      await userStore.updateUserRequest({
        userID: authStore.userAuthDetails.attributes.sub,
        end_session: true
      })

      authStore.logout();
      router.push('/');
    }

    watch(request_speaker_access, () => {
      updateUser('requesting_speaker');
    })

    const updateUser = ( field ) => {

      let requestField;

      if(field == 'requesting_speaker') requestField = 'request_speaker_access';
      else if(field == 'name') requestField = 'change_name';
      else if(field == 'location') requestField = 'change_location';

      console.log(requestField, userStore.userData[field]);

      userStore.updateUserRequest({
        userID: authStore.userAuthDetails.attributes.sub,
        [requestField]: userStore.userData[field]
      })

    }

    const totalWords = (selectedLevel) => {

      let res = { available: { total: 0 }, learned: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, total: 0 }};

      for(let level in wordStore.wordCounts)
      {
        let myWordsThisLevel = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, total: 0 };
        let availableWordsThisLevel = { total: 0 };

        res.available.total += wordStore.wordCounts[level];
        availableWordsThisLevel.total += wordStore.wordCounts[level];

        
        for(let word in userStore.myWords)
        {
          if(!wordStore.words[word]) continue
          if(level == selectedLevel && wordStore.words[word].level == selectedLevel) 
          {
            myWordsThisLevel[userStore.myWords[word].level]++;
            myWordsThisLevel['total']++;
          } else if(wordStore.words[word].level == level)
          {
            res.learned.total++;
            res.learned[userStore.myWords[word].level]++;
          }


          
        }
        
        if(level == selectedLevel)
        {
          return { available: availableWordsThisLevel, learned: myWordsThisLevel }
        }


      }

      return res
    }


    return {
        colors,
        showModal,
        modalMessage,
        userStore,
        authStore,
        pulseValue,
        pulseIncrement,
        wordLevelColours,
        updateUser,
        signOut,
        totalWords,
        request_speaker_access,

    }
  },

  async mounted(){

  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
#wordListTable {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

#wordListTable td {
  width: 250px;
  height: 20px !important;
  max-height: 20px !important;
  vertical-align: middle;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


.ball {
  display: inline-block;
  margin: 0;
  border-radius: 50%;
  z-index: 99999;
}
</style>
