export const onCreateWord = /* GraphQL */ `
  subscription OnCreateWord {
    onCreateWord {
      sro
      definition
      type
      syllabics
      level
      num_syllables
      dependencies
      morpheme {
        sro
        definition
        syllabics
      }
      morphemeWordsId
    }
  }
`;
export const onUpdateWord = /* GraphQL */ `
  subscription OnUpdateWord {
    onUpdateWord {
      sro
      definition
      type
      syllabics
      level
      num_syllables
      dependencies
      morpheme {
        sro
        definition
        syllabics
      }
      createdAt
      updatedAt
      morphemeWordsId
    }
  }
`;
export const onDeleteWord = /* GraphQL */ `
  subscription OnDeleteWord {
    onDeleteWord {
      sro
      definition
      type
      syllabics
      level
      morphemeWordsId
    }
  }
`;

export const onCreateMorpheme = /* GraphQL */ `
  subscription OnCreateMorpheme {
    onCreateMorpheme {
      sro
      definition
      syllabics
      words{
        items{
            sro
            definition
            type
            syllabics
            level
            dependencies
            morphemeWordsId
        }
      }
    }
  }
`;
export const onUpdateMorpheme = /* GraphQL */ `
  subscription OnUpdateMorpheme {
    onUpdateMorpheme {
      sro
      definition
      active
      syllabics
    }
  }
`;
export const onDeleteMorpheme = /* GraphQL */ `
  subscription OnDeleteMorpheme {
    onDeleteMorpheme {
      sro
      definition
      syllabics
    }
  }
`;

export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      id
      user_name
      name
      location
      speaker
      requesting_speaker
      level
      xp
      createdAt
      updatedAt
      userWordPointsId
      userRequestsId
      userTrainingInstanceId
    }
  }
`;


export const onUpdateUserRequest = /* GraphQL */ `
  subscription OnUpdateUserRequest($userID: String!) {
    onUpdateUserRequest(userID: $userID) {
      userID
      unlock_words
      unlock_phrase
      confirm_word
      submit_answer
      start_training
      answer_string
      next_session
      end_session
      change_name
      change_location
      request_speaker_access
      createdAt
      updatedAt
      request_hint
    }
  }
`;
export const onCreateUserWord = /* GraphQL */ `
  subscription OnCreateUserWord($userID: String!) {
    onCreateUserWord(userID: $userID) {
      sro
      userID
      bucketID
      level
      xp
      user_confirmed
      createdAt
      updatedAt
      userWordsId
    }
  }
`;
export const onUpdateUserWord = /* GraphQL */ `
  subscription OnUpdateUserWord($userID: String!) {
    onUpdateUserWord(userID: $userID) {
      sro
      userID
      bucketID
      level
      xp
      user_confirmed
      createdAt
      updatedAt
      userWordsId
    }
  }
`;

export const onCreateUserSyllable = /* GraphQL */ `
  subscription OnCreateUserSyllable($userID: String!) {
    onCreateUserSyllable(userID: $userID) {
      sro
      userID
      bucketID
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;

export const onUpdateUserSyllable = /* GraphQL */ `
  subscription OnUpdateUserSyllable($userID: String!) {
    onUpdateUserSyllable(userID: $userID) {
      sro
      userID
      bucketID
      createdAt
      updatedAt
      userSyllablesId
    }
  }
`;

export const onUpdateUserWordPoint = /* GraphQL */ `
  subscription OnUpdateUserWordPoint($userID: String!) {
    onUpdateUserWordPoint(userID: $userID) {
      userID
      points
      createdAt
      updatedAt
      userWordPointUserId
    }
  }
`;

export const onCreateTrainingInstance = /* GraphQL */ `
  subscription OnCreateTrainingInstance($userID: String!) {
    onCreateTrainingInstance(userID: $userID) {
      userID
      definition
      num_syllables
      num_letters
      createdAt
      updatedAt
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      word_xp_awarded
      user_xp_awarded
      clue_audio_bucketID
	    clue_audio_key
      clue_audio_user_id
      word_level
      component_results
      space_locations
      hints
      type
    }
  }
`;

export const onUpdateTrainingInstance = /* GraphQL */ `
  subscription OnUpdateTrainingInstance($userID: String!) {
    onUpdateTrainingInstance(userID: $userID) {
      userID
      definition
      num_syllables
      num_letters
      status
      availableSyllables
      submitted_answer
      correct_answer
      total_millis
      word_xp_awarded
      user_xp_awarded
      time_bonus
      syllabics_bonus
      clue_audio_bucketID
	    clue_audio_key
      clue_audio_user_id
      word_level
      createdAt
      updatedAt
      component_results
      space_locations
      hints
      type
    }
  }
`;

export const onDeleteTrainingInstance = /* GraphQL */ `
  subscription OnDeleteTrainingInstance($userID: String!) {
    onDeleteTrainingInstance(userID: $userID) {
      userID
    }
  }
`;

export const onCreateUserLevel = /* GraphQL */ `
  subscription OnCreateUserLevel($userID: String!) {
    onCreateUserLevel(userID: $userID) {
      userID
      level
      intro_complete
    }
  }
`;
export const onUpdateUserLevel = /* GraphQL */ `
  subscription OnUpdateUserLevel($userID: String!) {
    onUpdateUserLevel(userID: $userID) {
      userID
      level
      intro_complete
    }
  }
`;